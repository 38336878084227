import axios from 'axios'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import { apiError } from 'utils/alerts'
import store from 'store'
const { REACT_APP_URL } = process.env

const BaseService = axios.create({
    timeout: 60000,
    baseURL: REACT_APP_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})

BaseService.interceptors.request.use(
    config => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        let accessToken = persistData.auth.session.token

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    response => response,
    error => {
        console.log('Error: ', error)
        apiError(error)
        return Promise.reject(error)
    }
)

export default BaseService
