import React from 'react'
import { Outlet } from 'react-router-dom'

export default function LoginLayout() {
    return (
        <div className="flex flex-col items-center min-h-screen p-10 bg-gray dark:bg-boxdark2 dark:text-bodydark">
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="flex flex-wrap items-center p-8">
                    <div
                        className="hidden w-full bg-center bg-cover xl:block xl:w-1/2"
                        style={{ backgroundImage: `url(/images/bgimage.png)` }}
                    >
                        <div className="px-10 text-center py-7">
                            <h2 className="text-2xl font-bold text-black">PMS</h2>
                            <p className="2xl:px-20">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit suspendisse.
                            </p>
                            <span className="inline-block w-100">
                                <img src="/images/Logo.png" alt="error" />
                            </span>
                        </div>
                    </div>

                    <div className="w-full max-w-xl border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
                        <div className="w-full p-5 sm:p-6 xl:p-10">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
