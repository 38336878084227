import { Button } from 'components/Form'
import React from 'react'

export default function Error() {
    const handleGoBack = () => {
        window.history.back()
    }

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <h1 className="text-4xl font-bold">Oops! 404</h1>
                <p className="text-lg">We can't find the page you're looking for.</p>
                <div className="flex justify-center">
                    <Button className="text-white bg-primary" onClick={handleGoBack}>
                        Go Back
                    </Button>
                </div>
            </div>
        </div>
    )
}
