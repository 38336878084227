import React, { useEffect, useMemo, useState } from 'react'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch } from 'react-redux'
import { getData } from './store/dataSlice'
import { Modal, Table, Tooltip } from 'components/Ui'
import { useSelector } from 'react-redux'
import { setTableData } from './store/dataSlice'
import { Link } from 'react-router-dom'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { ImCopy } from 'react-icons/im'
import { johnStatus, status } from 'components/helper'
import { Field, Form, Formik } from 'formik'
import { Button, DatePicker, Select } from 'components/Form'
import * as Yup from 'yup'
import { getApiData } from 'services/TechnicianService'
import { assignTechnicianApi, sendMailApi, updateJohnStatusApi } from 'services/EventService'
import { Success } from 'utils/alerts'
import dayjs from 'dayjs'
import { johnStatusOptions } from 'utils/options'
const { REACT_APP_URL } = process.env

injectReducer('table', reducer)

const validationSchema = Yup.object().shape({
    technician_id: Yup.number().required('please select technicians'),
})

const validationSchema1 = Yup.object().shape({
    technician_id: Yup.number().required('please select technicians'),
    selected_date: Yup.date().required('please select mail date'),
})

const validationSchema2 = Yup.object().shape({
    john_status: Yup.string().required('please select john status'),
})

export default function Schedules() {
    const dispatch = useDispatch()
    const { data, loading, tableData } = useSelector(state => state.table.data)
    const [selectedIds, setSelectedIds] = useState([])
    const [open, setOpen] = useState(false)
    const [openMail, setOpenMail] = useState(false)
    const [openJohnStatus, setOpenJohnStatus] = useState(false)
    const [techniciansData, setTechniciansData] = useState([])
    const [imageView, setOPenImageView] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [eventId, setEventId] = useState('')

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab, tableData.suburb, tableData.startDate, tableData.endDate])

    const fetchData = () => dispatch(getData({ ...tableData }))

    useEffect(() => {
        getTechniciansData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getTechniciansData = () => {
        getApiData()
            .then((response) => {
                setTechniciansData(response.data?.results?.map((item) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                }))
            })
            .catch((error) => console.log('Error: ', error))
    }

    const handleAssign = (data, setSubmitting) => {
        setSubmitting(true)
        assignTechnicianApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Assigned Successfully!')
                fetchData();
                setOpen(false);
                setSelectedIds([]);
            })
            .catch((error) => {console.log('Error: ', error); setSubmitting(false)})
    }

    const handleSendMail = (data, setSubmitting) => {
        setSubmitting(true)
        sendMailApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Mail Send Successfully!')
                fetchData();
                setOpenMail(false);
            })
            .catch((error) => {console.log('Error: ', error); setSubmitting(false)})
    }

    const handleJohnStatus = (data, setSubmitting) => {
        setSubmitting(true)
        updateJohnStatusApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Status Updated Successfully!')
                fetchData();
                setOpenJohnStatus(false);
            })
            .catch((error) => {console.log('Error: ', error); setSubmitting(false)})
    }

    const columns = useMemo(
        () => [
            {
                field: 'date_of_job',
                header: 'Date',
                orderable: true,
                className: 'min-w-[150px]',
                renderCell: row => dayjs(row?.date_of_job).format('DD-MMM-YYYY'),
            },
            {
                field: 'builder',
                header: 'Builder',
                className: 'min-w-[150px]',
                renderCell: row => row?.builder?.name,
            },
            {
                field: 'type_of_job',
                header: 'Job',
                className: 'min-w-[150px]',
                renderCell: row => row?.type_of_job?.name,
            },
            {
                field: 'suburb',
                header: 'Suburb',
                className: 'min-w-[150px]',
            },
            {
                field: 'address',
                header: 'Address',
                className: 'min-w-[150px]',
            },
            {
                field: 'po',
                header: 'Po',
                className: 'min-w-[150px]',
            },
            {
                field: 'status',
                header: 'Status',
                className: 'min-w-[150px]',
                renderCell: row => status(row.is_active)
            },
            {
                field: 'job_status',
                header: 'Job Status',
                className: 'min-w-[150px]',
            },
            {
                field: 'john_status',
                header: 'John Status',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        <span
                            className='cursor-pointer'
                            onClick={() => {
                                setEventId(row?.id);
                                setOpenJohnStatus(true);
                            }}
                        >
                            {johnStatus(row?.john_status ?? '')}
                        </span>
                    )
                },
            },
            {
                field: 'technicians',
                header: 'Technicians',
                className: 'min-w-[150px]',
                renderCell: row => row?.latest_technician?.technician?.name
            },
            {
                field: 'images',
                header: 'Images',
                className: 'min-w-[150px]',
                renderCell: row => {
                    const imagesPerRow = 3; // Number of images per row
                    const imageSize = 'w-8 h-8'; // Set smaller size for the images
            
                    // Group images into rows
                    const groupedImages = [];
                    for (let i = 0; i < row?.images.length; i += imagesPerRow) {
                        groupedImages.push(row?.images.slice(i, i + imagesPerRow));
                    }
            
                    return (
                        row.is_active ? 
                            <div>
                                {groupedImages.map((group, rowIndex) => (
                                    <div key={rowIndex} className="flex justify-start mb-2">
                                        {group.map((item, index) => (
                                            <div className='flex justify-center gap-0 mr-2' key={index}>
                                                <span className={`${imageSize}`}
                                                    onClick={() => {(setImageUrl(item?.image_url)); setOPenImageView(true)}}
                                                >
                                                    <img src={`${REACT_APP_URL}${item?.image_url}`} alt="error" className="object-cover" />
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>  
                        : null
                    );
                },
            },
            {
                field: 'action',
                header: 'Action',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        row.is_active ? <div className="flex items-center gap-3">
                            <Tooltip title={'Edit'}>
                                <Link to={`/booking-form/${row.id}/edit`} className="hover:text-primary">
                                    <HiOutlinePencilAlt size={20} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={'Copy'}>
                                <Link to={`/booking-form/${row.id}/copy`} className="hover:text-primary">
                                    <ImCopy size={20} />
                                </Link>
                            </Tooltip>
                        </div> : null
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab, tableData.suburb, tableData.startDate, tableData.endDate]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Schedules</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    select={true}
                    toggleButton={true}
                    dateFilter={true}
                    suburb_filter={true}
                    filter={false}
                    mail={true}
                    assign_user={true}
                    loading={loading}
                    datas={data}
                    ids={selectedIds}
                    setIds={(e) => setSelectedIds(e)}
                    columns={columns}
                    add_new={'/booking-form/create'}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                    onMailClick={() => setOpenMail(true)}
                    onAssignUserClick={() => setOpen(true)}
                />
            </div>
            {/* assign technician modal */}
            <Modal
                show={open}
                onClose={() => {
                    setOpen(false)
                    setSelectedIds([])
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {'Assign Technicians'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={{ technician_id: '', events_ids: selectedIds }}
                                validationSchema={validationSchema}
                                onSubmit={(values, {setSubmitting}) => {
                                    handleAssign(values, setSubmitting)
                                }}
                            >
                                {({ values, touched, errors, isSubmitting }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="technician_id">
                                                {({ field, form }) => (
                                                    <Select
                                                        options={techniciansData}
                                                        label="Technician"
                                                        size="sm"
                                                        placeholder="Select"
                                                        className="md:col-span-2"
                                                        name="technician_id"
                                                        error={errors.technician_id && touched.technician_id}
                                                        message={errors.technician_id}
                                                        value={values?.technician_id}
                                                        onChange={e => { form.setFieldValue(field.name, e.target.value) }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => {setOpen(false);setSelectedIds([])}}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* send mail modal */}
            <Modal
                show={openMail}
                onClose={() => {
                    setOpenMail(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {'Send Mail'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={{ technician_id: '', selected_date: '' }}
                                validationSchema={validationSchema1}
                                onSubmit={(values, {setSubmitting}) => {
                                    handleSendMail(values, setSubmitting)
                                }}
                            >
                                {({ values, touched, errors, isSubmitting }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="technician_id">
                                                {({ field, form }) => (
                                                    <Select
                                                        options={techniciansData}
                                                        label="Technician"
                                                        size="sm"
                                                        placeholder="Select"
                                                        className="md:col-span-2"
                                                        name="technician_id"
                                                        error={errors.technician_id && touched.technician_id}
                                                        message={errors.technician_id}
                                                        value={values?.technician_id}
                                                        onChange={e => { form.setFieldValue(field.name, e.target.value) }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="selected_date">
                                                {({ field, form }) => (
                                                    <DatePicker
                                                        className="md:col-span-2"
                                                        label="Mail Date"
                                                        name="selected_date"
                                                        error={errors.selected_date && touched.selected_date}
                                                        message={errors.selected_date}
                                                        value={values?.selected_date ? dayjs(values.selected_date).format('YYYY-MM-DD') : ''}
                                                        onChange={e => {form.setFieldValue(field.name, dayjs(e.target.value).format('DD MMM YYYY'))
                                                            console.log(dayjs(e.target.value).format('DD MMM YYYY'))
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => setOpenMail(false)}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* john status model */}
            <Modal
                show={openJohnStatus}
                onClose={() => {
                    setOpenJohnStatus(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {'Update John Status'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={{ john_status: '', event_id: eventId }}
                                validationSchema={validationSchema2}
                                onSubmit={(values, {setSubmitting}) => {
                                    handleJohnStatus(values, setSubmitting)
                                }}
                            >
                                {({ values, touched, errors, isSubmitting }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="john_status">
                                                {({ field, form }) => (
                                                    <Select
                                                        options={johnStatusOptions}
                                                        label="John Status"
                                                        size="sm"
                                                        placeholder="Select"
                                                        className="md:col-span-2"
                                                        name="john_status"
                                                        error={errors.john_status && touched.john_status}
                                                        message={errors.john_status}
                                                        value={values?.john_status}
                                                        onChange={e => { form.setFieldValue(field.name, e.target.value) }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => setOpenJohnStatus(false)}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* image view model */}
            <Modal
                show={imageView}
                maxWidth='sm'
                onClose={() => {
                    setOPenImageView(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {'View Image'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex justify-center item-center p-6">
                            <img src={`${REACT_APP_URL}${imageUrl}`} alt="error" className="object-cover w-[400px] h-[300px]" />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
