import { forwardRef, useRef, useState, useEffect } from "react";
import { FiChevronDown } from "react-icons/fi";

const AutoCompleteSelect = forwardRef(function AutoCompleteSelect(
  {
    id = "",
    label = "",
    placeholder = "",
    className = "",
    error = "",
    message = "",
    options = [],
    value = "",
    onChange,
    ...props
  },
  ref
) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value?.toString());
  const inputRef = useRef();
  const dropdownRef = useRef();
  const containerRef = useRef();

  // Close dropdown on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (isOpen && !searchTerm) {
          onChange('');
        }
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isOpen, searchTerm]);

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    option?.label?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  // Handle selecting/deselecting options
  const handleSelect = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
    setSearchTerm(selectedOption.label);
  };

  return (
    <div className={className} ref={containerRef}>
      {label && (
        <label
          htmlFor={id}
          className={`block mb-1 font-medium ${error ? "text-danger" : "text-black dark:text-white"}`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <div
          className={`relative bg-white dark:bg-input ${`w-full rounded-lg border font-medium outline-none transition ${error ? "border-danger focus:ring-danger focus:border-danger" : "border-stroke focus:border-primary active:border-primary dark:focus:border-secondary dark:active:border-secondary dark:border-form"} ${
            props.disabled ? "bg-whiter dark:bg-black" : ""
          }`}`}
          onClick={() => {
            setIsOpen(true);
            inputRef.current?.focus();
          }}
        >
          <div className="flex flex-wrap items-center p-2 gap-1">
            <input
              ref={inputRef}
              type="text"
              className={`flex-grow min-w-[120px] h-[25px] border-none bg-transparent outline-none`}
              placeholder={placeholder}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setIsOpen(true)}
            />

            <FiChevronDown
              size={18}
            />
          </div>
        </div>

        {isOpen && (
          <div
            ref={dropdownRef}
            className="absolute z-50 w-full bg-white dark:bg-input border border-gray-200 dark:border-gray-600 shadow-lg max-h-60 overflow-auto"
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => {
                const isSelected = value?.toString() === option?.value?.toString() && searchTerm.toString() === option?.value?.toString();

                return (
                  <div
                    key={index}
                    className={`px-4 py-1 cursor-pointer hover:bg-[red] hover:text-white ${
                      isSelected ? "bg-[red] text-white" : ""
                    }`}
                    onClick={() => handleSelect(option)}
                  >
                    {option.label}
                  </div>
                );
              })
            ) : (
              <div className="px-4 py-1 text-gray-500">No options found</div>
            )}
          </div>
        )}
      </div>

      {error && <p className="mt-1 text-sm text-danger">{message}</p>}
    </div>
  );
});

export default AutoCompleteSelect;
