import React from 'react'
import { HiOutlineCalendar, HiOutlineDatabase } from 'react-icons/hi'
import { GrAppsRounded } from 'react-icons/gr'
import { MdOutlinePostAdd } from "react-icons/md";

const menuItems = [
    { id: 0, label: 'Dashboard', module: 'dashboard', link: '/', icon: <GrAppsRounded size={20} /> },
    {
        id: 1,
        label: 'Schedules',
        module: 'schedules',
        icon: <HiOutlineCalendar size={20} />,
        children: [
            { id: 11, label: 'Table', module: 'table', link: '/schedules/table' },
            { id: 12, label: 'Calender', module: 'calender', link: '/schedules/calender' },
        ]
    },
    {
        id: 2,
        label: 'Master Data',
        module: 'master-data',
        icon: <HiOutlineDatabase size={20} />,
        children: [
            { id: 21, label: 'Builder', module: 'builder', link: '/master-data/builder' },
            { id: 23, label: 'Job', module: 'job', link: '/master-data/job' },
            { id: 23, label: 'Technicians', module: 'technicians', link: '/master-data/technicians' },
            { id: 24, label: 'Booked by', module: 'bookedby', link: '/master-data/booked-by' },
        ]
    },
    { id: 3, label: 'Booking Form', module: 'booking-form', link: '/booking-form/create', icon: <MdOutlinePostAdd size={20} /> },
]

export default menuItems
