import { useState } from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import Modal from './Modal'
import { Button } from 'components/Form'

export default function ConfirmationModal({ children, onConfirm, title = '', message = '', className = '' }) {
    const [confirming, setConfirming] = useState(false)

    const closeModal = () => {
        setConfirming(false)
    }
    return (
        <div>
            <button className={className} onClick={() => setConfirming(true)}>
                {children}
            </button>
            <Modal show={confirming} onClose={closeModal} maxWidth="md">
                <div className="p-6 text-center">
                    <span className="inline-block mx-auto">
                        <div className="flex items-center justify-center w-20 h-20 transition rounded-full bg-warning/10 text-warning">
                            <FiAlertCircle size={40} />
                        </div>
                    </span>
                    <h3 className="pb-2 mt-5 text-xl font-bold text-black dark:text-white sm:text-2xl">
                        {title ? title : 'Confirmation Alert!'}
                    </h3>
                    <p className={`font-medium mb-10`}>{message ? message : 'Are you sure you want to change status?'}</p>
                    <div className="flex flex-wrap mx-5 gap-y-4">
                        <div className="w-full px-3 xsm:w-1/2">
                            <Button
                                className="w-full text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                onClick={() => {
                                    closeModal()
                                    onConfirm()
                                }}
                            >
                                Yes
                            </Button>
                        </div>
                        <div className="w-full px-3 xsm:w-1/2">
                            <Button type="button" className="w-full text-white border-danger bg-danger" onClick={closeModal}>
                                No
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
