import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiLogIn, getApiProfile } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { useNavigate } from 'react-router-dom'
import { Success } from 'utils/alerts'

function useAuth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token, signedIn } = useSelector(state => state.auth.session)
    const userInfo = useSelector(state => state.auth.user)
    const { permissionList } = useSelector(state => state.listData)

    const signIn = async values => {
        try {
            const resp = await apiLogIn(values)
            if (resp.data) {
                const { access_token, refresh_token, status } = resp.data
                dispatch(onSignInSuccess({ access: access_token, refresh: refresh_token }))
                getApiProfile()
                .then((resp) => {
                    if (resp.data) {
                        dispatch(
                            setUser(resp.data)
                        )
                    }
                })
                .catch((error) => console.log(error))

                return {
                    status: 'success',
                    message: '',
                    new_user: status === 'warning'
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: '',
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate('/login')
    }

    const signOut = async () => {
        // apiLogOut({ refresh: refreshToken })
        //     .then(() => {
                Success('Logout successfully!')
                handleSignOut()
            // })
            // .catch(error => console.log('Error: ', error))
    }

    const checkPermission = (module, action) => {
        if (userInfo.super_user || module === 'dashboard') return true
        let permissionsData = userInfo?.permissions ? [...userInfo?.permissions] : []
        const allPermissions = Object.keys(permissionList)
            .map(key => permissionList[key])
            .flat(Infinity)
        let status = false
        permissionsData = permissionsData.map(item => allPermissions.find(({ id }) => id === item.id))
        permissionsData.map(item => {
            if (action) {
                if (module === item?.module && action === item?.action) {
                    status = true
                }
            } else {
                if (module === item?.module) {
                    status = true
                }
            }
            return item
        })

        return status
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
        checkPermission,
    }
}

export default useAuth
