import { forwardRef, useEffect, useRef, useState } from 'react'
import { IconContext } from 'react-icons'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

export default forwardRef(function TextInput(
    {
        type = 'text',
        id = '',
        label = '',
        placeholder = '',
        className = '',
        icon = '',
        error = '',
        message = '',
        leftSpan = false,
        isFocused = false,
        password = false,
        ...props
    },
    ref
) {
    const [secureText, setSecureText] = useState(password)
    const input = useRef()

    useEffect(() => {
        if (isFocused) {
            input.current.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div className="relative flex items-stretch flex-nowrap">
                {leftSpan && (
                    <span className="flex items-center px-3 text-black border border-r-0 rounded-lg rounded-r-none border-stroke bg-whiter dark:bg-white/30 dark:text-white dark:border-form">
                        {leftSpan}
                    </span>
                )}
                <input
                    {...props}
                    type={secureText ? 'password' : type}
                    id={id}
                    placeholder={placeholder ? placeholder : label}
                    className={`${leftSpan && 'rounded-l-none'} w-full flex-auto rounded-lg border bg-transparent py-2 pl-3 ${
                        (password || icon) && 'pr-9'
                    } ${error ? 'border-danger focus:ring-danger focus:border-danger' : 'border-stroke focus:ring-primary focus:border-primary dark:border-form dark:bg-input dark:focus:ring-secondary dark:focus:border-secondary'} outline-none disabled:cursor-not-allowed disabled:bg-whiter dark:disabled:bg-black`}
                    ref={ref || input}
                />
                {password && (
                    <span className="absolute -translate-y-1/2 top-1/2 right-4" onClick={() => setSecureText(e => !e)}>
                        <IconContext.Provider value={{ size: '22' }}>
                            {secureText ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </IconContext.Provider>
                    </span>
                )}
                {icon && (
                    <span className="absolute -translate-y-1/2 top-1/2 right-4">
                        <IconContext.Provider value={{ color: '#c4cad3', size: '22' }}>{icon}</IconContext.Provider>
                    </span>
                )}
            </div>
            {error && <p className="text-sm text-danger">{message}</p>}
        </div>
    )
})
