import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getApiData as getCompanyDataApi } from 'services/TechnicianScheduleService'

export const getCompanyData = createAsyncThunk('listdata/getCompanyData', async () => {
    const response = await getCompanyDataApi()
    return response.data.results
        ?.map(item => {
            item.label = item.name
            item.value = item.id
            return item
        })
        .filter(item => item.status === 'active')
})

const dataSlice = createSlice({
    name: 'listdata',
    initialState: {
        companyData: [],
    },
    reducers: {
        setCompanyData: (state, { payload }) => {
            state.companyData = payload
        },
    },
    extraReducers: builder => {
        builder
            //company data
            .addCase(getCompanyData.pending, state => {
                state.loading = true
            })
            .addCase(getCompanyData.fulfilled, (state, action) => {
                state.loading = false
                state.companyData = action.payload
            })
            .addCase(getCompanyData.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    },
})

export const { setCompanyData } = dataSlice.actions

export default dataSlice.reducer
