import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AutoSelect,
  Button,
  DatePicker,
  Select,
  TextArea,
  TextInput,
} from "components/Form";
import {
  createApiRecord,
  updateApiRecord,
  getPostcode,
  getSuburb,
} from "services/EventService";
import { getApiData as getBuilderDataApi } from "services/BuilderService";
import { getApiData as getJobDataApi } from "services/JobService";
import { createApiRecord as jobCreateApi } from "services/JobService";
import { createApiRecord as bookedByCreateApi } from "services/BookedbyService";
import { getApiData as getBookedbyDataApi } from "services/BookedbyService";
import { useDispatch, useSelector } from "react-redux";
import { getRecord } from "./store/dataSlice";
import reducer from "./store";
import { injectReducer } from "store";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Success } from "utils/alerts";
import dayjs from "dayjs";
import { categoryOptions, timeofDayOptions } from "utils/options";
import { InfoField, Modal, Tooltip } from "components/Ui";
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";
injectReducer("bookingForm", reducer);

const validationSchema = Yup.object().shape({
  subject_line: Yup.string().required("subject line is required"),
  attendees: Yup.string()
    .required("attendees email is required")
    .email("attendees email is invalid")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "attendees email is invalid"),
  builder: Yup.number().required("please select builder"),
  type_of_job: Yup.number().required("please select type of job"),
  postcode: Yup.string().required("postcode is required"),
  address: Yup.string().required("address  is required"),
  suburb: Yup.string().required("suburb  is required"),
  date_of_job: Yup.date().required("date of job is required"),
  time_of_job: Yup.string().required("time of job is required"),
  po: Yup.string().required("po is required"),
  site_supervisor: Yup.string().required("site supervisor is required"),
  booked_by: Yup.number().required("please select booked by"),
  framer_date: Yup.date().nullable().optional(),
  pour_date: Yup.date().nullable().optional(),
  note: Yup.string().nullable().optional(),
  category: Yup.string().nullable().optional(),
  supplementary_text: Yup.string().nullable().optional(),
});

const headers = ["Basic Price", "Quantity", "LM", "Miscellaneous"];

const BookingForm = (props) => {
  const { initialData } = props;
  const { id } = useParams();
  const location = useLocation();
  const { record, loading } = useSelector((state) => state.bookingForm.data);
  const [builderData, setBuilderData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [bookedbyData, setBookedbyData] = useState([]);
  const [postcodeOptions, setPostcodeOptions] = useState([]);
  const [suburbOptions, setSuburbOptions] = useState([]);
  const [open, setOPen] = useState(false);
  const [data, setData] = useState(initialData);
  console.log(data);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = (id) => dispatch(getRecord(id));

  const handleDiscard = () => navigate("/schedules/table");

  useEffect(() => {
    getBuilderData();
    getJobData();
    getBookedbyData();
    getPostcodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (record) {
      getSuburbs({ postcode: record?.postcode });
    }
  }, [record]);

  const getBuilderData = () => {
    getBuilderDataApi()
      .then((response) => {
        setBuilderData(
          response.data?.results
            ?.filter((item) => item.is_active)
            .map((item) => {
              item.label = item.name;
              item.value = item.id;
              return item;
            })
        );
      })
      .catch((error) => console.log("Error: ", error));
  };

  const getJobData = () => {
    getJobDataApi()
      .then((response) => {
        const data = response.data?.results
          ?.filter((item) => item.is_active)
          .map((item) => {
            item.label = item.name;
            item.value = item.id;
            return item;
          });
        setJobData([...data, { label: "OTHERS", value: -1 }]);
      })
      .catch((error) => console.log("Error: ", error));
  };

  const getBookedbyData = () => {
    getBookedbyDataApi()
      .then((response) => {
        const data = response.data?.results
          ?.filter((item) => item.is_active)
          .map((item) => {
            item.label = item.name;
            item.value = item.id;
            return item;
          });
        setBookedbyData([...data, { label: "OTHERS", value: -1 }]);
      })
      .catch((error) => console.log("Error: ", error));
  };

  const getPostcodes = () => {
    getPostcode()
      // fetch(`/your_endpoint_path/events/postcode-suburb?postcode=${query}`)
      // .then((response) => response.json())
      .then((response) => {
        console.log("data", response.data);
        const postcodes = response.data?.postcodes?.map((code) => ({
          label: code.toString(),
          value: code,
        }));

        setPostcodeOptions(postcodes);
      })
      .catch((error) => console.error("Error:", error));
  };

  const getSuburbs = (postcode) => {
    getSuburb(postcode)
      .then((response) => {
        const suburbs = response.data?.suburbs?.map((suburb) => ({
          label: suburb,
          value: suburb,
        }));

        setSuburbOptions(suburbs || []);
      })
      .catch((error) => console.error("Error fetching suburbs:", error));
  };

  const handleSubmit = async (value) => {
    try {
      if (value.type_of_job === "-1") {
        var job = await jobCreateApi({ name: value.job_name });
      }
      if (value.booked_by === "-1") {
        var bookedby = await bookedByCreateApi({ name: value.booked_by_name });
      }
      value.type_of_job =
        value.type_of_job === "-1" ? job.data.id : value.type_of_job;
      value.booked_by =
        value.booked_by === "-1" ? bookedby.data.id : value.booked_by;
      if (id && !location.pathname.includes("copy")) {
        updateApiRecord(id, value)
          .then((response) => {
            Success("Updated Successfully!");
            navigate("/schedules/table");
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      } else {
        delete value?.id;
        delete value?.ms_event_id;
        delete value?.job_status;
        delete value?.john_status;
        createApiRecord(value)
          .then((response) => {
            Success("Created Successfully!");
            navigate("/schedules/table");
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  if (loading && id) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
        <h2 className="font-semibold text-black text-title-md2 dark:text-white">
          Booking Form
        </h2>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="px-5 py-3 border-b border-stroke dark:border-strokedark">
            <h3 className="font-medium text-black dark:text-white">
              {id ? "Update" : "Add New"}
            </h3>
          </div>
          <div className="flex flex-col gap-3 p-6">
            <Formik
              initialValues={
                id
                  ? {
                      ...record,
                      price_table: record.price_table.length
                        ? record.price_table
                        : [
                            {
                              basic_price: "",
                              quantity: "",
                              lm: "",
                              miscellaneous: "",
                            },
                          ],
                      type_of_job: location.pathname.includes("copy")
                        ? ""
                        : record.type_of_job,
                    }
                  : {
                      ...initialData,
                      subject_line: "JOHN",
                    }
              }
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                // handleSubmit(values, setSubmitting);
                setData(values);
                setOPen(true);
                setSubmitting(false);
              }}
            >
              {({ values, touched, errors, isSubmitting }) => (
                <Form>
                  <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                    <Field name="subject_line">
                      {({ field, form }) => (
                        <TextInput
                          type="text"
                          className="md:col-span-2"
                          label="Subject Line"
                          name="subject_line"
                          error={errors.subject_line && touched.subject_line}
                          message={errors.subject_line}
                          value={values?.subject_line}
                          onChange={(e) =>
                            form.setFieldValue(
                              field.name,
                              e.target.value?.toUpperCase()
                            )
                          }
                        />
                      )}
                    </Field>
                    <Field name="attendees">
                      {({ field, form }) => (
                        <TextInput
                          type="text"
                          className="md:col-span-2"
                          label="Attendees"
                          placeholder="Enter Email Id"
                          name="attendees"
                          error={errors.attendees && touched.attendees}
                          message={errors.attendees}
                          value={values?.attendees}
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <Field name="contractor_id">
                      {({ field, form }) => (
                        <TextInput
                          type="text"
                          className="md:col-span-2"
                          label="Builder/Supervisor Id"
                          placeholder="Enter Email Id"
                          name="contractor_id"
                          error={errors.contractor_id && touched.contractor_id}
                          message={errors.contractor_id}
                          value={values?.contractor_id}
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <Field name="builder">
                      {({ field, form }) => (
                        <Select
                          options={builderData}
                          label="Builder"
                          size="sm"
                          placeholder="Select"
                          className="md:col-span-2"
                          name="builder"
                          error={errors.builder && touched.builder}
                          message={errors.builder}
                          value={values?.builder}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                        />
                      )}
                    </Field>
                    <Field name="type_of_job">
                      {({ field, form }) => (
                        <Select
                          options={jobData}
                          label="Type of Job"
                          size="sm"
                          placeholder="Select"
                          className={`md:col-span-${values.type_of_job === "-1" ? "1" : "2"}`}
                          name="type_of_job"
                          error={errors.type_of_job && touched.type_of_job}
                          message={errors.type_of_job}
                          value={values?.type_of_job}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                        />
                      )}
                    </Field>
                    {values.type_of_job === "-1" ? (
                      <Field name="job_name">
                        {({ field, form }) => (
                          <TextInput
                            type="text"
                            className="md:col-span-1"
                            label="Job Name"
                            name="job_name"
                            error={errors.job_name && touched.job_name}
                            message={errors.job_name}
                            value={values?.job_name}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                        )}
                      </Field>
                    ) : null}
                    <Field name="date_of_job">
                      {({ field, form }) => (
                        <DatePicker
                          className="md:col-span-2"
                          label="Date of Job"
                          name="date_of_job"
                          error={errors.date_of_job && touched.date_of_job}
                          message={errors.date_of_job}
                          value={
                            values?.date_of_job
                              ? dayjs(values.date_of_job).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <Field name="time_of_job">
                      {({ field, form }) => (
                        <TextInput
                          className="md:col-span-2"
                          type="time"
                          label="Time of Job"
                          name="time_of_job"
                          error={errors.time_of_job && touched.time_of_job}
                          message={errors.time_of_job}
                          value={values?.time_of_job}
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <Field name="time_of_day">
                      {({ field, form }) => (
                        <Select
                          options={timeofDayOptions}
                          label="Time of Day"
                          size="sm"
                          placeholder="Select"
                          className="md:col-span-2"
                          name="time_of_day"
                          error={errors.time_of_day && touched.time_of_day}
                          message={errors.time_of_day}
                          value={values?.time_of_day}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                        />
                      )}
                    </Field>
                    <Field name="pour_date">
                      {({ field, form }) => (
                        <DatePicker
                          className="md:col-span-2"
                          label="Pour Date"
                          name="pour_date"
                          error={errors.pour_date && touched.pour_date}
                          message={errors.pour_date}
                          value={
                            values?.pour_date
                              ? dayjs(values.pour_date).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <Field name="framer_date">
                      {({ field, form }) => (
                        <DatePicker
                          className="md:col-span-2"
                          label="Framer Date"
                          name="framer_date"
                          error={errors.framer_date && touched.framer_date}
                          message={errors.framer_date}
                          value={
                            values?.framer_date
                              ? dayjs(values.framer_date).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <Field name="supplementary_text">
                      {({ field, form }) => (
                        <TextInput
                          type="text"
                          className="md:col-span-2"
                          label="Supplementary text"
                          name="supplementary_text"
                          error={
                            errors.supplementary_text &&
                            touched.supplementary_text
                          }
                          message={errors.supplementary_text}
                          value={values?.supplementary_text}
                          onChange={(e) =>
                            form.setFieldValue(
                              field.name,
                              e.target.value?.toUpperCase()
                            )
                          }
                        />
                      )}
                    </Field>
                    <Field name="po">
                      {({ field, form }) => (
                        <TextInput
                          type="text"
                          className="md:col-span-2"
                          label="Po"
                          name="po"
                          error={errors.po && touched.po}
                          message={errors.po}
                          value={values?.po}
                          onChange={(e) =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      )}
                    </Field>
                    <Field name="site_supervisor">
                      {({ field, form }) => (
                        <TextInput
                          type="text"
                          className="md:col-span-2"
                          label="Site Supervisor"
                          name="site_supervisor"
                          error={
                            errors.site_supervisor && touched.site_supervisor
                          }
                          message={errors.site_supervisor}
                          value={values?.site_supervisor}
                          onChange={(e) =>
                            form.setFieldValue(
                              field.name,
                              e.target.value?.toUpperCase()
                            )
                          }
                        />
                      )}
                    </Field>
                    <Field name="booked_by">
                      {({ field, form }) => (
                        <Select
                          options={bookedbyData}
                          label="Booked by"
                          size="sm"
                          placeholder="Select"
                          className={`md:col-span-${values.booked_by === "-1" ? "1" : "2"}`}
                          name="booked_by"
                          error={errors.booked_by && touched.booked_by}
                          message={errors.booked_by}
                          value={values?.booked_by}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                        />
                      )}
                    </Field>
                    {values.booked_by === "-1" ? (
                      <Field name="booked_by_name">
                        {({ field, form }) => (
                          <TextInput
                            type="text"
                            className="md:col-span-1"
                            label="Booked by Name"
                            name="booked_by_name"
                            error={
                              errors.booked_by_name && touched.booked_by_name
                            }
                            message={errors.booked_by_name}
                            value={values?.booked_by_name}
                            onChange={(e) =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                        )}
                      </Field>
                    ) : null}

                    <Field name="postcode">
                      {({ field, form }) => (
                        <AutoSelect
                          options={postcodeOptions}
                          label="Postcode"
                          size="sm"
                          placeholder="Select"
                          className={`md:col-span-2 ${form.errors.postcode && form.touched.postcode ? 'error-class' : ''}`}
                          name="postcode"
                          error={errors.postcode && touched.postcode}
                          message={errors.postcode}
                          value={values?.postcode ? values.postcode : ""}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e);
                            getSuburbs({ postcode: e });
                            form.setFieldValue("suburb", "");
                          }}
                        />
                      )}
                    </Field>
                    <Field name="suburb">
                      {({ field, form }) => (
                        <Select
                          options={suburbOptions}
                          label="Suburb"
                          size="sm"
                          placeholder="Select"
                          className="md:col-span-2"
                          name="suburb"
                          error={errors.suburb && touched.suburb}
                          message={errors.suburb}
                          value={values?.suburb}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                        />
                      )}
                    </Field>

                    <Field name="category">
                      {({ field, form }) => (
                        <Select
                          options={categoryOptions}
                          label="Category"
                          size="sm"
                          placeholder="Select"
                          className="md:col-span-2"
                          name="category"
                          error={errors.category && touched.category}
                          message={errors.category}
                          value={values?.category}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                        />
                      )}
                    </Field>
                    <Field name="address">
                      {({ field, form }) => (
                        <TextInput
                          type="text"
                          className="md:col-span-2"
                          label="Address"
                          name="address"
                          error={errors.address && touched.address}
                          message={errors.address}
                          value={values?.address}
                          onChange={(e) =>
                            form.setFieldValue(
                              field.name,
                              e.target.value?.toUpperCase()
                            )
                          }
                        />
                      )}
                    </Field>
                    <Field name="price_table">
                      {({ field, form }) => (
                        <div className="overflow-auto md:col-span-4">
                          <label
                            htmlFor={id}
                            className={`block mb-1 font-medium text-black dark:text-white'}`}
                          >
                            {"Price"}
                          </label>
                          <table className="w-full table-auto">
                            <thead>
                              <tr
                                className={`bg-primary dark:bg-secondary bg-opacity-90 text-left`}
                              >
                                {headers?.map((item) => (
                                  <th
                                    key={item}
                                    className="px-3 py-2 font-medium text-white md:px-3 2xl:px-3"
                                  >
                                    {item}
                                  </th>
                                ))}
                                <th className="px-3 py-2 font-medium text-white md:px-3 2xl:px-3">
                                  Total
                                </th>
                                <th className="px-3 py-2 font-medium text-white md:px-3 2xl:px-3">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.price_table?.map((item, index) => (
                                <tr
                                  key={index}
                                  className="hover:bg-whiten dark:hover:bg-boxdark2"
                                >
                                  {Object.keys(item).map((key) => (
                                    <td
                                      key={key}
                                      className="px-3 py-2 md:px-3 2xl:px-3"
                                    >
                                      <TextInput
                                        type="text"
                                        className="md:col-span-2"
                                        placeholder={key}
                                        name={key}
                                        value={item[key]}
                                        onChange={(e) => {
                                          let arr = [...values?.price_table];
                                          arr[index][key] = e.target.value;
                                          form.setFieldValue(field.name, arr);
                                        }}
                                      />
                                    </td>
                                  ))}
                                  <td className="px-3 py-2 md:px-3 2xl:px-3">
                                    {parseFloat(
                                      item?.basic_price ? item?.basic_price : 0
                                    ) +
                                      parseFloat(item?.lm ? item?.lm : 0) *
                                        parseFloat(
                                          item?.quantity ? item?.quantity : 0
                                        ) +
                                      parseFloat(
                                        item?.miscellaneous
                                          ? item?.miscellaneous
                                          : 0
                                      )}
                                  </td>
                                  <td className="px-3 py-2 md:px-3 2xl:px-3">
                                    <div className="flex items-center gap-3">
                                      {index ===
                                      values?.price_table?.length - 1 ? (
                                        <Tooltip title={"Add New"}>
                                          <span
                                            className="hover:text-primary"
                                            onClick={() => {
                                              let arr = [
                                                ...values?.price_table,
                                              ];
                                              arr.push({
                                                basic_price: "",
                                                quantity: "",
                                                lm: "",
                                                miscellaneous: "",
                                              });
                                              form.setFieldValue(
                                                field.name,
                                                arr
                                              );
                                            }}
                                          >
                                            <HiOutlinePlusCircle size={20} />
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title={"Remove"}>
                                          <span
                                            className="hover:text-danger"
                                            onClick={() => {
                                              let arr = [
                                                ...values?.price_table,
                                              ];
                                              arr.splice(index, 1);
                                              form.setFieldValue(
                                                field.name,
                                                arr
                                              );
                                            }}
                                          >
                                            <HiOutlineMinusCircle size={20} />
                                          </span>
                                        </Tooltip>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </Field>
                    <Field name="note">
                      {({ field, form }) => (
                        <TextArea
                          type="text"
                          className="md:col-span-4"
                          label="Note"
                          name="note"
                          error={errors.note && touched.note}
                          message={errors.note}
                          value={values?.note}
                          onChange={(e) =>
                            form.setFieldValue(
                              field.name,
                              e.target.value?.toUpperCase()
                            )
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className="flex items-center justify-end gap-4 mt-3">
                    <Button
                      className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                      onClick={handleDiscard}
                    >
                      Discard
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Modal
        show={open}
        // maxWidth="sm"
        onClose={() => {
          setOPen(false);
        }}
      >
        <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
          <h2 className="font-semibold text-black text-title-md dark:text-white">
            {"Booking Form Details"}
          </h2>
          <div className="mr-10">
            <button
              onClick={() => handleSubmit(data)}
              className="flex items-center justify-center cursor-pointer rounded-lg border py-1 px-2 transition hover:bg-opacity-90 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
            >
              Confirm
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="overflow-y-auto max-h-[450px] py-4">
              <div className="grid grid-cols-1 gap-4 px-8">
                <InfoField title="Subject Line" value={data?.subject_line} />
                <InfoField title="Attendees" value={data?.attendees} />
                <InfoField
                  title="Builder/Supervisor Id"
                  value={data?.contractor_id}
                />
                <InfoField
                  title="Builder"
                  value={
                    builderData?.find((item) => item.value?.toString() === data?.builder?.toString())
                      ?.label
                  }
                />
                <InfoField
                  title="Type of job"
                  value={
                    jobData?.find((item) => item.value?.toString() === data?.type_of_job?.toString())
                      ?.label
                  }
                />
                <InfoField title="Date of job" value={data?.date_of_job} />
                <InfoField title="Time of job" value={data?.time_of_job} />
                <InfoField title="Time of day" value={data?.time_of_day} />
                <InfoField title="Pour Date" value={data?.pour_date} />
                <InfoField title="Framer Date" value={data?.framer_date} />
                <InfoField
                  title="Supplementary text"
                  value={data?.supplementary_text}
                />
                <InfoField title="Po" value={data?.po} />
                <InfoField
                  title="Site Supervisor"
                  value={data?.site_supervisor}
                />
                <InfoField
                  title="Booked by"
                  value={
                    bookedbyData?.find((item) => item.value?.toString() === data?.booked_by?.toString())
                      ?.label
                  }
                />
                <InfoField title="Postcode" value={data?.postcode} />
                <InfoField title="Suburb" value={data?.suburb} />
                <InfoField title="Category" value={data?.category} />
                <InfoField title="Address" value={data?.address} />
                <InfoField title="Note" value={data?.note} />
                <hr />
                <InfoField title="PRICES" value="" />
              </div>
              {data ? data?.price_table?.map((item, index) => (
                        <div className="grid grid-cols-1 gap-4 px-8 pt-4" key={index}>
                            <InfoField title="Basic Price" value={item?.basic_price ?? ''} />
                            <InfoField title="LM" value={item?.lm ?? ''} />
                            <InfoField title="Quantity" value={item?.quantity ?? ''} />
                            <InfoField title="Miscellaneous" value={item?.miscellaneous ?? ''} />
                            <InfoField title="Total Price" value={parseFloat(
                                      item?.basic_price ? item?.basic_price : 0
                                    ) +
                                      parseFloat(item?.lm ? item?.lm : 0) *
                                        parseFloat(
                                          item?.quantity ? item?.quantity : 0
                                        ) +
                                      parseFloat(
                                        item?.miscellaneous
                                          ? item?.miscellaneous
                                          : 0
                                      )} />
                        </div>
                    )) : null}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

BookingForm.defaultProps = {
  initialData: {
    builder: "",
    type_of_job: "",
    booked_by: "",
    attendees: "",
    subject_line: "",
    date_of_job: "",
    time_of_job: "",
    time_of_day: "",
    framer_date: null,
    pour_date: null,
    po: "",
    site_supervisor: "",
    note: "",
    address: "",
    postcode: "",
    suburb: "",
    category: "",
    supplementary_text: "",
    price_table: [
      {
        basic_price: "",
        quantity: "",
        lm: "",
        miscellaneous: "",
      },
    ],
  },
};

export default BookingForm;
