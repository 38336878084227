import { Button, TextInput } from 'components/Form'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import { apiChangePassword } from 'services/AuthService'
import { onSignOutSuccess } from 'store/auth/sessionSlice'
import { useDispatch } from 'react-redux'

const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('old password is required'),
    new_password: Yup.string()
        .required('Enter your new password')
        .min(8, 'Too Short!')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
            'Must have Uppercase, Lowercase, Number and Special Character'
        ),
    confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Password not match'),
})

export default function ChangePassword() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onResetPassword = async (values, setSubmitting) => {
        apiChangePassword(values)
            .then(() => {
                dispatch(onSignOutSuccess())
                Success('Reset password successfully!')
                setSubmitting(false)
                navigate('/login')
            })
            .catch(error => console.log('Error:', error))
    }

    return (
        <>
            <h2 className="mb-3 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">Reset Password</h2>
            <Formik
                initialValues={{
                    old_password: '',
                    new_password: '',
                    confirm_password: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    onResetPassword(values, setSubmitting)
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form className="flex flex-col gap-3">
                        <Field name="old_password">
                            {({ field, form }) => (
                                <TextInput
                                    id="old_password"
                                    name="old_password"
                                    label={'Old Password'}
                                    autoComplete="old password"
                                    password={true}
                                    error={errors.old_password && touched.old_password}
                                    message={errors.old_password}
                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                        <Field name="new_password">
                            {({ field, form }) => (
                                <TextInput
                                    id="new_password"
                                    name="new_password"
                                    label={'New Password'}
                                    autoComplete="new password"
                                    password={true}
                                    error={errors.new_password && touched.new_password}
                                    message={errors.new_password}
                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                        <Field name="confirm_password">
                            {({ field, form }) => (
                                <TextInput
                                    id="confirm_password"
                                    name="confirm_password"
                                    label={'Confirm Password'}
                                    autoComplete="confirm password"
                                    password={true}
                                    error={errors.confirm_password && touched.confirm_password}
                                    message={errors.confirm_password}
                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                        <Button
                            className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                            disabled={false}
                            type="submit"
                        >
                            {false ? 'resetting...' : 'Reset Password'}
                        </Button>
                        <div className="text-center">
                            <p className="font-medium">
                                Back to{' '}
                                <Link to="/login" className="hover:underline">
                                    Sign in
                                </Link>
                            </p>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}
