import ApiService from './ApiService'

export const getApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.status = data.selectedTab
        }
        if (data.startDate) {
            datas.start_date = data.startDate
        }
        if (data.endDate) {
            datas.end_date = data.endDate
        }
    }
    return ApiService.get('technician/assigned-tasks/', datas)
}
export const getApiEvents = () => ApiService.get('technician/assigned-tasks/')
export const assignTechnicianApi = data => ApiService.post('technician/assigned-tasks/', data)
export const sendMailApi = data => ApiService.post('technician/assigned-tasks/', data)
export const updateJobStatusApi = data => ApiService.post('technician/update-job-status/', data)
export const updateIssueApi = data => ApiService.post('technician/update-issue-status/', data)
export const deleteImageApi = data => ApiService.post('technician/delete-image/', data)
export const uploadImageApi = data => ApiService.filePost('taskimages/', data)

