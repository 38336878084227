import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getApiRecord } from 'services/EventService'

export const getRecord = createAsyncThunk('bookingForm/data/getRecord', async id => {
    const response = await getApiRecord(id)
    const data = {
        ...response.data,
        booked_by: response.data?.booked_by?.id,
        builder: response.data?.builder?.id,
        type_of_job: response.data?.type_of_job?.id,
        price_table: response.data?.price_table.map((item) => {
            const obj = {
                basic_price: item?.basic_price,
                quantity: item?.quantity,
                lm: item?.lm,
                miscellaneous: item?.miscellaneous
            }
            return obj
        })
    }
    return data
})

const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 50,
    query: '',
    selectedTab: '',
    sort: [{ column: '', dir: '' }],
}

const dataSlice = createSlice({
    name: 'bookingForm/data',
    initialState: {
        loading: true,
        tableData: initialTableData,
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.tableData = payload
        },
    },
    extraReducers: bookingForm => {
        bookingForm
            .addCase(getRecord.fulfilled, (state, { payload }) => {
                state.loading = false
                state.record = payload
            })
            .addCase(getRecord.pending, state => {
                state.loading = true
                state.record = null
            })
            .addCase(getRecord.rejected, state => {
                state.loading = false
                state.record = null
            })
    },
})

export const { setData, setTableData } = dataSlice.actions

export default dataSlice.reducer
