import { forwardRef, useRef } from 'react'
import { FiChevronDown } from 'react-icons/fi'

export default forwardRef(function Select(
    {
        id = '',
        label = '',
        placeholder = '',
        className = '',
        error = '',
        message = '',
        options = [],
        value = '',
        multiple = false,
        onChange,
        ...props
    },
    ref
) {
    const input = useRef()

    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div
                className={`relative bg-white dark:bg-input ${
                    multiple &&
                    `w-full rounded-lg border  p-1 font-medium outline-none transition ${error ? 'border-danger focus:ring-danger focus:border-danger' : 'border-stroke focus:border-primary active:border-primary dark:focus:border-secondary dark:active:border-secondary dark:border-form'} ${
                        props.disabled ? 'bg-whiter dark:bg-black' : ''
                    }`
                }`}
            >
                {multiple && (
                    <div className="flex flex-wrap items-center">
                        {value &&
                            value?.map((e, index) => {
                                var labelCheck = options.find(op => op.value.toString() === e.toString())
                                if (!labelCheck) return null
                                return (
                                    <span
                                        key={index}
                                        className="flex items-center justify-center px-1 py-1 text-xs font-medium border rounded m-[3px] border-stroke bg-gray dark:border-strokedark dark:bg-white/30"
                                    >
                                        {labelCheck.label}
                                        {/* <span className='pl-2 cursor-pointer hover:text-danger'>
                                        <FiX />
                                    </span> */}
                                    </span>
                                )
                            })}
                        {value && value?.filter(e => options.find(op => op.value.toString() === e.toString())).length === 0 && (
                            <span className="px-1 py-1 text-base">{placeholder}</span>
                        )}
                    </div>
                )}
                <select
                    {...props}
                    id={id}
                    value={multiple ? '' : value}
                    placeholder={label ? label : placeholder}
                    className={`w-full appearance-none rounded-lg border bg-transparent py-2 px-3 outline-none transition ${error ? 'border-danger focus:ring-danger focus:border-danger' : 'border-stroke focus:border-primary active:border-primary dark:focus:border-secondary dark:active:border-secondary dark:border-form'} dark:bg-input disabled:cursor-not-allowed disabled:bg-whiter dark:disabled:bg-black ${
                        multiple && 'absolute top-0 left-0 z-20 h-full opacity-0'
                    }`}
                    ref={input}
                    onChange={e => {
                        if (!multiple) return onChange(e)
                        const select = e.target.value
                        if (!select) return
                        var values = [...value]
                        if (values.find(item => item.toString() === select.toString())) {
                            values = values.filter(e => e.toString() !== select.toString())
                        } else {
                            values.push(select)
                        }
                        onChange(values)
                    }}
                >
                    {placeholder && <option value="">{placeholder}</option>}
                    {options.map((option, index) => (
                        <option
                            value={option.value}
                            key={index}
                            className={
                                multiple
                                    ? value?.find(item => item.toString() === option.value.toString())
                                        ? 'bg-gray dark:bg-white/30'
                                        : ''
                                    : ''
                            }
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
                {multiple && (
                    <span className="absolute -translate-y-1/2 top-1/2 right-4">
                        <FiChevronDown size={18} />
                    </span>
                )}
            </div>
            {error && <p className="text-sm text-danger">{message}</p>}
        </div>
    )
})
