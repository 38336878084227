import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import store from 'store'
import { onSignOutSuccess } from 'store/auth/sessionSlice'

export const apiError = error => {
    let text = null
    const { response, message } = error
    if (response) {
        const { status, data, message } = response
        if (status === 401) {
            store.dispatch(onSignOutSuccess())
        } else if (status === 422) {
            if (typeof data.errors === 'string') {
                text = data.errors
            } else if (typeof data.errors === 'object') {
                for (const key in data.errors) {
                    if (typeof data.errors[key] === 'string') {
                        Error(data.errors[key])
                    } else {
                        for (const keys in data.errors[key]) {
                            Error(key + ' ' + data.errors[key][keys])
                        }
                    }
                }
            }
        } else if (status === 404) {
            text = '404 Not Found'
        } else if (status === 405) {
            text = '405 Method Not Allowed'
        } else if (status === 500) {
            text = '500 Internal Server Error'
        } else if (data && [400, 403].includes(status)) {
            Object.keys(data).map(key => {
                if (Array.isArray(data[key])) {
                    data[key].map(e => {
                        Error(e)
                        return e
                    })
                } else {
                    Error(data[key])
                }
                return key
            })
        } else if (message) text = message
    } else if (message) text = message
    if (text) Error(text)
}

export const Error = error => {
    const colorTheme = localStorage.getItem('color-theme')
    var data = {
        theme: colorTheme === null ? 'light' : colorTheme,
    }
    return toast.error(error, data)
}

export const Success = message => {
    const colorTheme = localStorage.getItem('color-theme')
    var data = {
        theme: colorTheme === null ? 'light' : colorTheme,
    }
    return toast.success(message, data)
}
