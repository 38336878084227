import React, { useEffect, useState } from 'react'
import { Button, Select, TextInput } from 'components/Form'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import { getApiProfile, updateApiProfile } from 'services/AuthService'
import { City, Country, State } from 'country-state-city'
import { setUser } from 'store/auth/userSlice'
import { useDispatch, useSelector } from 'react-redux'

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('first name is required'),
    last_name: Yup.string().required('last name is required'),
    mobile: Yup.number()
        .required('contact number is required')
        .max(999999999999, 'contact number must be between 7 to 12 digits')
        .min(9999999, 'contact number must be between 7 to 12 digits'),
    email: Yup.string()
        .required('email is required')
        .email('email is invalid')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'email is invalid'),
    address_line_1: Yup.string().required('address is required'),
    country: Yup.string().required('please select country'),
    state: Yup.string().required('please select state'),
    city: Yup.string().required('please select city'),
    zip_code: Yup.number().nullable().optional(),
})

const Profile = props => {
    const { initialData } = props
    const dispatch = useDispatch()
    const [profileData, setProfileData] = useState('')
    const [countryData, setCountryData] = useState([])
    const [stateData, setStateData] = useState([])
    const [cityData, setCityData] = useState([])

    const userInfo = useSelector(state => state.auth.user)

    useEffect(() => {
        setCountryData(
            Country.getAllCountries().map(item => {
                item.label = item.name
                item.value = item.name
                return item
            })
        )
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = () => {
        getApiProfile()
            .then(response => {
                setProfileData(response.data)
                const country = Country.getAllCountries().find(country => country.name === response.data.country)
                getStateData(country.isoCode)
                const state = State.getStatesOfCountry(country.isoCode).find(state => state.name === response.data.state)
                getCityData(state?.countryCode, state?.isoCode)
            })
            .catch(error => console.log('Error: ', error))
    }

    const getStateData = country => {
        setStateData(
            State.getStatesOfCountry(country).map(item => {
                item.label = item.name
                item.value = item.name
                return item
            })
        )
    }

    const getCityData = (country, state) => {
        setCityData(
            City.getCitiesOfState(country, state).map(item => {
                item.label = item.name
                item.value = item.name
                return item
            })
        )
    }

    const handleSubmit = (value, { setSubmitting }) => {
        setSubmitting(true)
        updateApiProfile(value)
            .then(response => {
                const user = {
                    ...userInfo,
                    first_name: response.data.first_name ?? 'User',
                    last_name: response.data.last_name ?? '',
                    email: response.data.email,
                }
                dispatch(setUser(user))
                fetchData()
                setSubmitting(false)
                Success('Profile Updated Successfully!')
            })
            .catch(error => {
                setSubmitting(false)
                console.log('Error: ', error)
            })
    }

    if (!profileData) {
        return null
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Profile</h2>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="flex flex-col gap-3 p-6">
                        <Formik
                            initialValues={profileData ? profileData : initialData}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, touched, errors, isSubmitting, resetForm }) => (
                                <Form>
                                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                                        <Field name="first_name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="First Name"
                                                    name="first_name"
                                                    error={errors.first_name && touched.first_name}
                                                    message={errors.first_name}
                                                    value={values?.first_name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="last_name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Last Name"
                                                    name="last_name"
                                                    error={errors.last_name && touched.last_name}
                                                    message={errors.last_name}
                                                    value={values?.last_name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="email">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Email"
                                                    name="email"
                                                    disabled={true}
                                                    error={errors.email && touched.email}
                                                    message={errors.email}
                                                    value={values?.email}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="country">
                                            {({ field, form }) => (
                                                <Select
                                                    options={countryData}
                                                    label="Country"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="country"
                                                    error={errors.country && touched.country}
                                                    message={errors.country}
                                                    value={values?.country}
                                                    onChange={e => {
                                                        form.setFieldValue(field.name, e.target.value)
                                                        form.setFieldValue('state', '')
                                                        form.setFieldValue('city', '')
                                                        setCityData([])
                                                        const country = countryData.find(
                                                            country => country.name === e.target.value
                                                        )
                                                        form.setFieldValue('country_code', `+${country.phonecode}`)
                                                        getStateData(country.isoCode)
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <div className="md:col-span-2">
                                            <div className="grid grid-cols-7 gap-x-2">
                                                <Field name="country_code">
                                                    {({ field, form }) => (
                                                        <TextInput
                                                            type="text"
                                                            className="md:col-span-2"
                                                            label="Country Code"
                                                            name="country_code"
                                                            disabled
                                                            value={values?.country_code}
                                                            onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <Field name="mobile">
                                                    {({ field, form }) => (
                                                        <TextInput
                                                            type="text"
                                                            className="md:col-span-5"
                                                            label="Contact Number"
                                                            name="mobile"
                                                            error={errors.mobile && touched.mobile}
                                                            message={errors.mobile}
                                                            value={values?.mobile}
                                                            onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <Field name="state">
                                            {({ field, form }) => (
                                                <Select
                                                    options={stateData}
                                                    label="State"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="state"
                                                    error={errors.state && touched.state}
                                                    message={errors.state}
                                                    value={values?.state}
                                                    onChange={e => {
                                                        form.setFieldValue(field.name, e.target.value)
                                                        form.setFieldValue('city', '')
                                                        const state = stateData.find(state => state.name === e.target.value)
                                                        getCityData(state?.countryCode, state?.isoCode)
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name="city">
                                            {({ field, form }) => (
                                                <Select
                                                    options={cityData}
                                                    label="City"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="city"
                                                    error={errors.city && touched.city}
                                                    message={errors.city}
                                                    value={values?.city}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="zip_code">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Zip Code"
                                                    name="zip_code"
                                                    error={errors.zip_code && touched.zip_code}
                                                    message={errors.zip_code}
                                                    value={values?.zip_code}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="flex items-center justify-end gap-4 mt-3">
                                        <Button
                                            type="button"
                                            className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                            onClick={resetForm}
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                        >
                                            {isSubmitting ? 'Updating...' : 'Update Profile'}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

Profile.defaultProps = {
    initialData: {
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        address_line_1: '',
        address_line_2: '',
        zip_code: '',
        country_code: '',
        country: '',
        state: '',
        city: '',
    },
}

export default Profile
