import React, { useEffect, useMemo, useState } from 'react'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch } from 'react-redux'
import { getData } from './store/dataSlice'
import { Modal, Table, Tooltip } from 'components/Ui'
import { useSelector } from 'react-redux'
import { setTableData } from './store/dataSlice'
import { HiOutlinePencilAlt, HiOutlineXCircle } from 'react-icons/hi'
import { jobStatus, status } from 'components/helper'
import { Field, Form, Formik } from 'formik'
import { Button, FileUpload, Select, TextInput } from 'components/Form'
import * as Yup from 'yup'
import {deleteImageApi, updateIssueApi, updateJobStatusApi, uploadImageApi } from 'services/TechnicianScheduleService'
import { Success } from 'utils/alerts'
import dayjs from 'dayjs'
import { jobStatusOptions} from 'utils/options'
const { REACT_APP_URL } = process.env

injectReducer('table', reducer)

const validationSchema1 = Yup.object().shape({
    job_status: Yup.string().required('please select job status.'),
})
const validationSchema2 = Yup.object().shape({
    image_url: Yup.array().required('please upload image.'),
})
const validationSchema3 = Yup.object().shape({
    issue: Yup.string().required('please select Remark.'),
})

export default function TechnicianSchedules() {
    const dispatch = useDispatch()
    const { data, loading, tableData } = useSelector(state => state.table.data)
    const [selectedIds, setSelectedIds] = useState([])
    const [openJobStatus, setOpenJobStatus] = useState(false)
    const [openRemark, setOpenRemark] = useState(false)
    const [OpenUploadImage, setOpenUploadImage] = useState(false)
    const [imageView, setOPenImageView] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [eventId, setEventId] = useState('')

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab, tableData.startDate, tableData.endDate])

    const fetchData = () => dispatch(getData({ ...tableData }))

    const handleJobStatus = (data, setSubmitting) => {
        setSubmitting(true)
        updateJobStatusApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Status Updated Successfully!')
                fetchData();
                setOpenJobStatus(false);
            })
            .catch((error) => {console.log('Error: ', error); setSubmitting(false)})
    }

    const handleRemark = (data, setSubmitting) => {
        setSubmitting(true)
        updateIssueApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Remark Added Successfully!')
                fetchData();
                setOpenRemark(false);
            })
            .catch((error) => {console.log('Error: ', error); setSubmitting(false)})
    }

    const handleImageUpload = (data, setSubmitting) => {
        setSubmitting(true)
        uploadImageApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Image Uploaded Successfully!');
                fetchData(); // Refresh or update data if needed
                setOpenUploadImage(false); // Close the modal
            })
            .catch((error) => {console.log('Error: ', error); setSubmitting(false)});
    };
    
    // const handleImageUpload = async (data) => {
    //     const imageUrls = await Promise.all(
    //         Array.from(data.image_url).map(async (file) => {
    //             const base64 = await convertFileToBase64(file); // Convert file to base64
    //             return base64;
    //         })
    //     );
    
    //     // Now, you can add the base64 images to the data
    //     const payload = {
    //         ...data,
    //         image_url: imageUrls // Add the base64 images to the data
    //     };
    
    //     uploadImageApi(payload)
    //         .then(() => {
    //             Success('Images Uploaded Successfully!');
    //             fetchData(); // Refresh or update data if needed
    //             setOpenUploadImage(false); // Close the modal
    //         })
    //         .catch((error) => console.error('Error:', error));
    // };
    
    // Function to convert file to base64
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file); // Convert file to base64
        });
    };
    

    const columns = useMemo(
        () => [
            {
                field: 'date_of_job',
                header: 'Date',
                orderable: true,
                className: 'min-w-[150px]',
                renderCell: row => dayjs(row?.date_of_job).format('DD-MMM-YYYY'),
            },
            {
                field: 'builder',
                header: 'Builder',
                className: 'min-w-[150px]',
                renderCell: row => row?.builder?.name,
            },
            {
                field: 'type_of_job',
                header: 'Job',
                className: 'min-w-[150px]',
                renderCell: row => row?.type_of_job?.name,
            },
            {
                field: 'address',
                header: 'Address',
                className: 'min-w-[150px]',
            },
            {
                field: 'po',
                header: 'Po',
                className: 'min-w-[150px]',
            },
            {
                field: 'job_status',
                header: 'Job Status',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        <span
                            className='cursor-pointer'
                            onClick={() => {
                                setEventId(row?.id);
                                setOpenJobStatus(true);
                            }}
                        >
                            {jobStatus(row?.job_status ?? '')}
                        </span>
                    )
                },
            },
            {
                field: 'issue',
                header: 'Remarks',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        <span
                            className='cursor-pointer'
                            onClick={() => {
                                setEventId(row?.id);
                                setOpenRemark(true);
                            }}
                        >
                            {row.issue ?? "Give Remark"}
                        </span>
                    )
                },
            },
            {
                field: 'john_status',
                header: 'John Status',
                className: 'min-w-[150px]',
            },
            {
                field: 'images',
                header: 'Images',
                className: 'min-w-[150px]',
                renderCell: row => {
                    const imagesPerRow = 3; // Number of images per row
                    const imageSize = 'w-8 h-8'; // Set smaller size for the images
            
                    // Group images into rows
                    const groupedImages = [];
                    for (let i = 0; i < row?.images.length; i += imagesPerRow) {
                        groupedImages.push(row?.images.slice(i, i + imagesPerRow));
                    }
                    console.log(groupedImages);
                    
                    return (
                        row.is_active ? 
                            <div>
                                {groupedImages.map((group, rowIndex) => (
                                    <div key={rowIndex} className="flex justify-start mb-2">
                                        {group.map((item, index) => (
                                            <div className='flex justify-center gap-0 mr-2' key={index}>
                                                <span className={`${imageSize}`}
                                                    onClick={() => {(setImageUrl(item?.image_url)); setOPenImageView(true)}}
                                                >
                                                    <img src={`${REACT_APP_URL}${item?.image_url}`} alt="error" className="object-cover" />
                                                </span>
                                                <span
                                                    onClick={() => deleteImageApi({image_id:item.id})
                                                    .then(() => {
                                                        Success('Image Deleted Successfully!');
                                                        fetchData();
                                                    })
                                                    .catch((error) => console.error('Error:', error))
                                                }
                                                >
                                                    <HiOutlineXCircle size={15} />
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div> 
                        : null
                    );
                },
            },
            {
                field: 'action',
                header: 'Action',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        row.is_active ? <div className="flex items-center gap-3">
                            <Tooltip title={'Upload Image'}>
                                <span className="hover:text-primary"
                                    onClick={() => {
                                        setEventId(row?.id);
                                        setOpenUploadImage(true);
                                    }}
                                >
                                    <HiOutlinePencilAlt size={20} />
                                    
                                </span>
                            </Tooltip>
                        </div> : null
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab, tableData.startDate, tableData.endDate]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Schedules</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    select={false}
                    toggleButton={true}
                    dateFilter={true}
                    filter={false}
                    loading={loading}
                    datas={data}
                    ids={selectedIds}
                    setIds={(e) => setSelectedIds(e)}
                    columns={columns}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>

            {/* john status model */}
            <Modal
                show={openJobStatus}
                onClose={() => {
                    setOpenJobStatus(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {'Update Job Status'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={{ job_status: '', event_id: eventId }}
                                validationSchema={validationSchema1}
                                onSubmit={(values, {setSubmitting}) => {
                                    handleJobStatus(values, setSubmitting)
                                }}
                            >
                                {({ values, touched, errors, isSubmitting }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="job_status">
                                                {({ field, form }) => (
                                                    <Select
                                                        options={jobStatusOptions}
                                                        label="Job Status"
                                                        size="sm"
                                                        placeholder="Select"
                                                        className="md:col-span-2"
                                                        name="job_status"
                                                        error={errors.job_status && touched.job_status}
                                                        message={errors.job_status}
                                                        value={values?.job_status}
                                                        onChange={e => { form.setFieldValue(field.name, e.target.value) }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => setOpenJobStatus(false)}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* Remark Model */}
            <Modal
                show={openRemark}
                onClose={() => {
                    setOpenRemark(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {'Remark'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={{ issue: '', event_id: eventId }}
                                validationSchema={validationSchema3}
                                onSubmit={(values, {setSubmitting}) => {
                                    handleRemark(values, setSubmitting)
                                }}
                            >
                                {({ values, touched, errors, isSubmitting }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="issue">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Remark"
                                                        name="issue"
                                                        error={errors.issue && touched.issue}
                                                        message={errors.issue}
                                                        value={values?.issue}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => setOpenRemark(false)}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* image upload model */}
            <Modal
                show={OpenUploadImage}
                onClose={() => {
                    setOpenUploadImage(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {'Upload Images'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={{ image_url: '', event_id: eventId }}
                                validationSchema={validationSchema2}
                                onSubmit={(values, {setSubmitting}) => {
                                    handleImageUpload(values, setSubmitting)
                                }}
                            >
                                {({ values, touched, errors, isSubmitting }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="image_url">
                                                 {({ field, form }) => (
                                                     <FileUpload
                                                         label="Upload Image"
                                                         className="md:col-span-2"
                                                         multiple={true}
                                                         name="image_url"
                                                         error={errors.image_url && touched.image_url}
                                                         message={errors.image_url}
                                                         onChange={e =>
                                                            form.setFieldValue(
                                                                field.name,
                                                                Object.keys(e.target.files).map(key => e.target.files[key])
                                                            )
                                                        }
                                                     />
                                                 )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => setOpenUploadImage(false)}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* image view model */}
            <Modal
                show={imageView}
                maxWidth='sm'
                onClose={() => {
                    setOPenImageView(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {'View Image'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex justify-center item-center p-6">
                            <img src={`${REACT_APP_URL}${imageUrl}`} alt="error" className="object-cover w-[400px] h-[300px]" />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
