import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default function Calender(props) {

    const { data, onEventClick, onEventChange } = props;

    const datasSource = () => {
        var lists = [...data];
        lists = lists.map((item, index) => {
            let endDate = new Date(item.start);
            endDate.setMinutes(endDate.getMinutes() + 15);
            let newTimeStr = endDate.toTimeString().split(' ')[0];
            const newObj = {
                ...item,
                end: `${item.end} ${newTimeStr}`,
                backgroundColor: '#0000FF'
            }
            return newObj
        })
        return lists;
    };

    const eventClick = (e) => {
        onEventClick(e.event);
    };

    return (
        <div className="p-4 bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark md:p-6 xl:p-9">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                initialView="dayGridMonth"
                views={{ 
                    timeGridWeek: { titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }, 
                        dayHeaderFormat: { 
                            weekday: 'short',    // 'Sun'
                            day: 'numeric',      // '13'
                            month: 'short'       // 'Oct'
                        },
                        slotDuration: '00:15:00', 
                        slotLabelInterval: '00:15:00',
                        // slotLabelContent: function(info) {
                        //     const minutes = info.date.getMinutes();
                        //     const hours = info.date.getHours();
                            
                        //     if (minutes === 0) {
                        //         return `${hours}:00 - ${hours}:15`;
                        //     } else if (minutes === 15) {
                        //         return `${hours}:15 - ${hours}:30`;
                        //     } else if (minutes === 30) {
                        //         return `${hours}:31 - ${hours}:46`;
                        //     } else if (minutes === 45) {
                        //         return `${hours}:47 - ${hours + 1}:00`;
                        //     }
                        // },
                    },
                    timeGridDay: {
                        slotDuration: '00:15:00', 
                        slotLabelInterval: '00:15:00',
                    }
                }}
                events={datasSource()}
                dayMaxEvents={true}
                weekends={true}
                droppable={true}
                editable={true}
                selectable={false}
                selectMirror={false}
                eventClick={eventClick}
                eventChange={onEventChange}
            />
        </div>
    );
}
