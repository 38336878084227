import ApiService from './ApiService'

export const apiLogIn = data => ApiService.post('/technician/login/', data)
export const apiLogOut = data => ApiService.post('/userauth/logout/', data)
export const apiChangePassword = data => ApiService.post('/change-password/', data)
export const apiForgotPassword = data => ApiService.post('/send-reset-password-email/', data)
export const apiResetPassword = data => ApiService.post(`/reset-password/`, data)
export const getApiProfile = () => ApiService.get(`/technician/profile/`)
export const getApiAdminProfile = () => ApiService.get(`/admin-profile/`)
export const updateApiProfile = data => ApiService.put(`/user/profile/`, data)
