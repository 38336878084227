import { forwardRef, useRef } from 'react'

export default forwardRef(function FileUpload(
    { id = '', label = '', placeholder = '', className = '', error = '', message = '', ...props },
    ref
) {
    const input = useRef()
    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div className="relative">
                <input
                    {...props}
                    type="file"
                    id={id}
                    placeholder={label ? label : placeholder}
                    className={`w-full font-medium transition bg-transparent border rounded-lg outline-none cursor-pointer file:mr-3 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:bg-whiter file:py-2 file:px-3 ${error ? 'border-danger focus:ring-danger file:border-danger file:hover:bg-danger dark:file:hover:bg-danger focus:border-danger active:border-danger dark:focus:border-danger' : 'border-stroke file:border-stroke file:hover:bg-primary dark:file:hover:bg-secondary focus:border-primary active:border-primary dark:active:border-secondary dark:border-form dark:file:border-form dark:focus:border-secondary'} file:hover:bg-opacity-50 dark:file:hover:bg-opacity-50 disabled:cursor-not-allowed disabled:bg-whiter dark:bg-input dark:file:bg-white/30 dark:file:text-white`}
                    ref={ref || input}
                />
            </div>
            {error && <p className="text-sm text-danger">{message}</p>}
        </div>
    )
})
