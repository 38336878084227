import { Button, TextInput } from 'components/Form'
import { HiOutlineUser } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import { apiForgotPassword } from 'services/AuthService'

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Username is required'),
})

export default function ForgotPassword() {
    const navigate = useNavigate()
    const onForgotPassword = async (values, setSubmitting) => {
        apiForgotPassword(values)
            .then(() => {
                Success('A reset password link has been sent to your registered email successfully!')
                setSubmitting(false)
                navigate('/reset-password')
            })
            .catch(error => {setSubmitting(false);console.log('Error: ', error)})
    }

    return (
        <>
            <h2 className="mb-3 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">Forgot Password</h2>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    onForgotPassword(values, setSubmitting)
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form className="flex flex-col gap-3">
                        <Field name="email">
                            {({ field, form }) => (
                                <TextInput
                                    type="text"
                                    id="email"
                                    name="email"
                                    label={'Email'}
                                    autoComplete="email"
                                    isFocused={true}
                                    icon={<HiOutlineUser />}
                                    error={errors.email && touched.email}
                                    message={errors.email}
                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                        <Button
                            className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                            disabled={isSubmitting}
                            type="submit"
                        >
                            Send Email
                        </Button>
                        <div className="text-center">
                            <p className="font-medium">
                                Back to{' '}
                                <Link to="/login" className="hover:underline">
                                    Sign in
                                </Link>
                            </p>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}
