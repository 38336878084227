import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getApiData, getApiRecord } from 'services/TechnicianService'

export const getData = createAsyncThunk('technicians/data/getData', async data => {
    const response = await getApiData(data)
    return response.data
})

export const getRecord = createAsyncThunk('technicians/data/getRecord', async id => {
    const response = await getApiRecord(id)
    return response.data
})

const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 50,
    query: '',
    selectedTab: '',
    sort: [{ column: '', dir: '' }],
}

const dataSlice = createSlice({
    name: 'technicians/data',
    initialState: {
        loading: true,
        data: [],
        tableData: initialTableData,
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.tableData = payload
        },
    },
    extraReducers: tech => {
        tech
            .addCase(getData.pending, state => {
                state.loading = true
                state.tableData.total = 0
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.results
                state.tableData.total = payload.count
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
            })
            .addCase(getRecord.fulfilled, (state, { payload }) => {
                state.loading = false
                state.record = payload
            })
            .addCase(getRecord.pending, state => {
                state.loading = true
                state.record = null
            })
            .addCase(getRecord.rejected, state => {
                state.loading = false
                state.record = null
            })
    },
})

export const { setData, setTableData } = dataSlice.actions

export default dataSlice.reducer
