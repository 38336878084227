export default function Tooltip({ children, title = '', placement = 'top' }) {
    var divClass = ''
    var spanClass = ''
    switch (placement) {
        case 'right':
            divClass = 'left-full top-1/2 ml-2 -translate-y-1/2'
            spanClass = '-left-1 top-1/2 -translate-y-1/2 rounded-r-sm'
            break
        case 'bottom':
            divClass = 'top-full left-1/2 mt-2 -translate-x-1/2'
            spanClass = '-top-1 left-1/2 -translate-x-1/2 rounded-sm'
            break
        case 'left':
            divClass = 'right-full top-1/2 mr-2 -translate-y-1/2'
            spanClass = '-right-1 top-1/2 -translate-y-1/2 rounded-sm'
            break
        default:
            divClass = 'bottom-full left-1/2 mb-2 -translate-x-1/2'
            spanClass = '-bottom-1 left-1/2 -translate-x-1/2 rounded-l-sm'
            break
    }
    return (
        <>
            <div className="relative inline-block group">
                {children}
                <div
                    className={`absolute ${divClass} z-20 whitespace-nowrap rounded-md bg-boxdark3 dark:bg-white text-white dark:text-boxdark3 py-2 px-3 text-sm font-medium scale-0 transition-all drop-shadow-4 group-hover:scale-100`}
                >
                    <span className={`absolute ${spanClass} -z-10 h-2 w-2 rotate-45 bg-boxdark3 dark:bg-white`}></span>
                    {title}
                </div>
            </div>
        </>
    )
}
