import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "a6a469d2-9714-49c5-b27a-2f0c9c05d8b2",
    authority: "https://login.microsoftonline.com/7de6b8aa-e38d-4146-9324-30279f9c2579",
    redirectUri: "http://localhost:8000/callback",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
