import React, { useEffect, useState } from 'react'
import { injectReducer } from 'store'
import { Field, Form, Formik } from 'formik'
import { Button, Select } from 'components/Form'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData } from './store/dataSlice'
import Calender from 'components/Calender'
import { assignTechnicianApi, updateApiRecord } from 'services/EventService'
import { getApiData } from 'services/TechnicianService'
import { Success } from 'utils/alerts'
import { InfoField, Modal } from 'components/Ui'
import * as Yup from 'yup'
import dayjs from 'dayjs'

injectReducer('event', reducer)

export default function Schedules() {
    const dispatch = useDispatch()
    const { data } = useSelector(state => state.event.data)
    const [open, setOpen] = useState(false)
    const [techniciansData, setTechniciansData] = useState([])
    const [openAssignTechnicianModal, setOpenAssignTechnicianModal] = useState(false);
    const [event, setEvent] = useState('')
    const [selectedIds, setSelectedIds] = useState([]);

    const validationSchema = Yup.object({
        technician_id: Yup.string().required("Technician is required"),
    });
    console.log(event);
    
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const fetchData = () => dispatch(getData())

    useEffect(() => {
        getTechniciansData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTechniciansData = () => {
        getApiData()
            .then((response) => {
                setTechniciansData(response.data?.results?.map((item) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                }))
            })
            .catch((error) => console.log('Error: ', error))
    }

    const handleAssign = (data, setSubmitting) => {
        setSubmitting(true)
        assignTechnicianApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Assigned Successfully!')
                fetchData();
                setOpen(false);
                setSelectedIds([]);
            })
            .catch((error) => {console.log('Error: ', error); setSubmitting(false)})
    }

    const handleEventChange = (data) => {
        updateApiRecord(data.id, data)
          .then((response) => {
            Success("Updated Successfully!");
            fetchData();
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Schedules</h2>
                {/* Add New Button/Link */}
                <a href="/booking-form/create">
                    <button as="button" class="flex items-center justify-center cursor-pointer rounded-lg border py-2 px-6 transition hover:bg-opacity-90 undefined text-white border-primary bg-primary dark:border-secondary dark:bg-secondary">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path><path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path></svg>
                        Add New
                    </button>
                </a>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Calender
                    data={data}
                    onEventClick={(e) => {
                        setOpen(true);
                        setEvent({
                            title: e.title,
                            start: e.start,
                            end: e.end,
                            ...e.extendedProps
                        });
                    }}
                    onEventChange={(e)=> {
                        const data = {...e.event.extendedProps?.details}
                        const date = dayjs(e.event.start).format("YYYY-MM-DD hh:mm:ss")
                        data.date_of_job = date.split(" ")[0]
                        data.time_of_job = date.split(" ")[1]
                        data.booked_by = data.booked_by.id
                        data.builder = data.builder.id
                        data.type_of_job = data.type_of_job.id
                        handleEventChange(data);
                    }}
                />
            </div>
            <Modal
                show={open}
                onClose={() => {
                    setOpen(false);
                    setEvent('');
                }}
            >
                <div className="p-4 flex justify-between items-center">
                    <h2 className="font-semibold text-black text-title-md2 dark:text-white">Event Details</h2>
                    <div className="flex items-center space-x-2">
                    {/* Add Assign Technician Button */}
                    <button
                        onClick={() => setOpenAssignTechnicianModal(true)}
                        className="flex items-center justify-center cursor-pointer rounded-lg border py-2 px-6 transition hover:bg-opacity-90 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                    >
                        Assign Technician
                    </button>
                    {/* Add New Button/Link */}
                    <a href={`/booking-form/${event?.scheduled_task_id}/edit`}>
                        <button as="button" class="flex items-center justify-center cursor-pointer rounded-lg border py-2 px-6 mr-7 transition hover:bg-opacity-90 undefined text-white border-primary bg-primary dark:border-secondary dark:bg-secondary">
                           Edit
                        </button>
                    </a>
                    </div>
                </div>
                <hr />
                <div className='overflow-y-auto max-h-[450px] py-4'>
                    <div className="grid grid-cols-1 gap-4 px-8">
                        <InfoField title="Title" value={event?.title} />
                        <InfoField title="Start" value={event?.start?.toString()} />
                        <InfoField title="End" value={event?.end?.toString()} />
                        <hr />
                        <InfoField title="Builder" value={event?.builder} />
                        <InfoField title="Job Type" value={event?.type_of_job} />
                        <InfoField title="JOB ADDRESS" value={event?.address} />
                        <InfoField title="DATE" value={event?.start?.toString()} />
                        <InfoField title="POUR DATE" value={event?.pour_date} />
                        <InfoField title="PO" value={event?.po} />
                        <InfoField title="SITE SUPERVISOR" value={event?.site_supervisor} />
                        <InfoField title="TECHNICIAN ASSIGNED" value={event?.technician ? event?.technician : 'N/A'} />
                        <InfoField title="NOTE" value={event?.note ? event?.note : 'N/A'} />
                        <hr />
                        <InfoField title="PRICES" value="" />
                    </div>
                    {event ? event?.prices?.map((item, index) => (
                        <div className="grid grid-cols-1 gap-4 px-8 pt-4" key={index}>
                            <InfoField title="Basic Price" value={item?.basic_price ?? ''} />
                            <InfoField title="LM" value={item?.lm ?? ''} />
                            <InfoField title="Quantity" value={item?.quantity ?? ''} />
                            <InfoField title="Miscellaneous" value={item?.miscellaneous ?? ''} />
                            <InfoField title="Total Price" value={item?.total_price ?? ''} />
                        </div>
                    )) : null}
                </div>
            </Modal>

            <Modal
                show={openAssignTechnicianModal}
                onClose={() => {
                    setOpenAssignTechnicianModal(false);
                    setSelectedIds([]);
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        Assign Technicians
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={{ technician_id: '', events_ids: [event.scheduled_task_id] }}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    handleAssign(values, setSubmitting);
                                    setOpenAssignTechnicianModal(false);
                                }}
                            >
                                {({ values, touched, errors, isSubmitting }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="technician_id">
                                                {({ field, form }) => (
                                                    <Select
                                                        options={techniciansData}
                                                        label="Technician"
                                                        size="sm"
                                                        placeholder="Select"
                                                        className="md:col-span-2"
                                                        name="technician_id"
                                                        error={errors.technician_id && touched.technician_id}
                                                        message={errors.technician_id}
                                                        value={values?.technician_id}
                                                        onChange={e => {
                                                            form.setFieldValue(field.name, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => {
                                                    setOpenAssignTechnicianModal(false);
                                                    setSelectedIds([]);
                                                }}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
