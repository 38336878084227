import React from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import { AppLayout, LoginLayout } from 'components/layout'
import Login from 'views/auth/Login'
import ForgotPassword from 'views/auth/ForgotPassword'
import ResetPassword from 'views/auth/ResetPassword'
import ChangePassword from 'views/auth/ChangePassword'
import Error from 'views/Error'
import Home from 'views/Home'
import Profile from 'views/auth/Profile'
import SchedulesTable from 'views/schedules/schedulesTable'
import SchedulesCalender from 'views/schedules/schedulesCalender'
import Builder from 'views/masterdata/builder'
import CreateBuilder from 'views/masterdata/builder/create'
import Job from 'views/masterdata/job'
import CreateJob from 'views/masterdata/job/create'
import Technicians from 'views/masterdata/technicians'
import CreateTechnicians from 'views/masterdata/technicians/create'
import Bookedby from 'views/masterdata/bookedby'
import CreateBookedby from 'views/masterdata/bookedby/create'
import BookingForm from 'views/bookingform'
import TechnicianSchedules from 'views/technicianSchedules'

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <AppLayout />,
            children: [
                //dashboard
                { path: '/', element: <Home /> },
                // { path: '/:token', element: <Home /> },
                { path: '/dashboard/:token', element: <Home /> },

                //profile
                { path: '/profile', element: <Profile /> },

                //schedules
                {
                    path: '/schedules/table',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <SchedulesTable /> },
                    ],
                },
                {
                    path: '/schedules/calender',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <SchedulesCalender /> },
                    ],
                },

                //master data
                {
                    path: '/master-data/builder',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Builder /> },
                        { path: 'create', element: <CreateBuilder /> },
                        { path: ':id/edit', element: <CreateBuilder /> },
                    ],
                },
                {
                    path: '/master-data/job',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Job /> },
                        { path: 'create', element: <CreateJob /> },
                        { path: ':id/edit', element: <CreateJob /> },
                    ],
                },
                {
                    path: '/master-data/technicians',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Technicians /> },
                        { path: 'create', element: <CreateTechnicians /> },
                        { path: ':id/edit', element: <CreateTechnicians /> },
                    ],
                },
                {
                    path: '/master-data/booked-by',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Bookedby /> },
                        { path: 'create', element: <CreateBookedby /> },
                        { path: ':id/edit', element: <CreateBookedby /> },
                    ],
                },

                //booking form
                {
                    path: '/booking-form',
                    element: <Outlet />,
                    children: [
                        { path: 'create', element: <BookingForm /> },
                        { path: ':id/edit', element: <BookingForm /> },
                        { path: ':id/copy', element: <BookingForm /> },
                    ],
                },

                // technician schedules
                { path: '/schedules', element: <TechnicianSchedules /> },
            ],
        },
        {
            path: '/',
            element: <LoginLayout />,
            children: [
                { path: '/login', element: <Login /> },
                { path: '/forgot-password', element: <ForgotPassword /> },
                { path: '/reset-password', element: <ResetPassword /> },
                { path: '/change-password', element: <ChangePassword/> },
            ],
        },
        { path: 'error', element: <Error /> },
        { path: '*', element: <Navigate to="/error" replace /> },
    ])
}
