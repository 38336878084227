import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { HiOutlineUser } from 'react-icons/hi'

import UserOne from '../../../assets/images/user-02.png'
import { TbLogout2 } from 'react-icons/tb'
import useAuth from 'utils/hooks/useAuth'
import { useSelector } from 'react-redux'
import { RiRotateLockLine } from 'react-icons/ri'
import { Modal } from 'components/Ui'
import * as Yup from 'yup'
import { Button, TextInput } from 'components/Form'
import { Field, Form, Formik } from 'formik'
import { apiChangePassword } from 'services/AuthService'
import { Success } from 'utils/alerts'

const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('old password required'),
    new_password: Yup.string()
        .required('Enter your new password')
        .min(8, 'Too Short!')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
            'Must have Uppercase, Lowercase, Number and Special Character'
        ),
})

const DropdownUser = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const { signOut } = useAuth()
    const user = useSelector(state => state.auth.user)

    const trigger = useRef(null)
    const dropdown = useRef(null)

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return
            setDropdownOpen(false)
        }
        document.addEventListener('click', clickHandler)
        return () => document.removeEventListener('click', clickHandler)
    })

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return
            setDropdownOpen(false)
        }
        document.addEventListener('keydown', keyHandler)
        return () => document.removeEventListener('keydown', keyHandler)
    })

    const onChnagePassword = async (values, setSubmitting) => {
        apiChangePassword(values)
            .then(() => {
                Success('Password Updated successfully!')
                setSubmitting(false)
                setOpen(false)
            })
            .catch(error => {
                setSubmitting(false)
                console.log('Error:', error)
            })
    }



    return (
        <div className="relative inline-block text-left">
            <Link ref={trigger} onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center gap-1" to="#">
                <span className="hidden text-right lg:block">
                    <span className="block text-sm font-medium text-black dark:text-white">
                        {user.name ? user.name : 'User'}
                    </span>
                    <span className="block text-xs">{user.email}</span>
                </span>

                <span className="w-10 h-10 pl-1 rounded-full">
                    <img src={UserOne} alt="User" />
                </span>

                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="hidden fill-current sm:block"
                    height="25"
                    width="25"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
                </svg>
            </Link>

            {/* <!-- Dropdown Start --> */}
            <div
                ref={dropdown}
                onFocus={() => setDropdownOpen(true)}
                onBlur={() => setDropdownOpen(false)}
                className={`absolute right-0 mt-2 flex flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark origin-top-right sm:right-0 w-60 ${
                    dropdownOpen === true ? 'block' : 'hidden'
                }`}
            >
                <ul className="flex flex-col">
                    <li>
                        <Link
                            to="/profile"
                            className="flex items-center gap-3 px-4 py-3 text-sm font-medium duration-300 ease-in-out lg:text-base hover:bg-gray2 dark:hover:bg-strokedark"
                        >
                            <HiOutlineUser size={23} />
                            My Profile
                        </Link>
                    </li>
                    <li>
                        <span
                            onClick={() => {
                                setOpen(true)
                                setDropdownOpen(false)
                            }}
                            className="flex items-center gap-3 px-4 py-3 text-sm font-medium duration-300 ease-in-out lg:text-base hover:bg-gray2 dark:hover:bg-strokedark"
                        >
                            <RiRotateLockLine size={23} />
                            Change Password
                        </span>
                    </li>
                </ul>
                <button
                    onClick={() => signOut()}
                    className="flex items-center gap-3 px-4 py-3 text-sm font-medium duration-300 ease-in-out border-t lg:text-base hover:bg-gray2 dark:hover:bg-strokedark border-stroke dark:border-strokedark"
                >
                    <TbLogout2 size={23} />
                    Log Out
                </button>
            </div>
            {/* <!-- Dropdown End --> */}
            <Modal show={open} onClose={() => setOpen(false)}>
                <div className="p-6">
                    <h2 className="mb-4 font-semibold text-black text-title-md2 dark:text-white">Change Password</h2>
                    <Formik
                        initialValues={{
                            old_password: '',
                            new_password: '',
                            confirm_password: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            onChnagePassword(values, setSubmitting)
                        }}
                    >
                        {({ touched, errors, isSubmitting }) => (
                            <Form className="flex flex-col gap-3">
                                <Field name="old_password">
                                    {({ field, form }) => (
                                        <TextInput
                                            id="old_password"
                                            name="old_password"
                                            label={'Old Password'}
                                            autoComplete="old password"
                                            password={true}
                                            error={errors.old_password && touched.old_password}
                                            message={errors.old_password}
                                            onChange={e => form.setFieldValue(field.name, e.target.value)}
                                        />
                                    )} 
                                </Field>
                                <Field name="new_password">
                                    {({ field, form }) => (
                                        <TextInput
                                            id="new_password"
                                            name="new_password"
                                            label={'New Password'}
                                            autoComplete="new password"
                                            password={true}
                                            error={errors.new_password && touched.new_password}
                                            message={errors.new_password}
                                            onChange={e => form.setFieldValue(field.name, e.target.value)}
                                        />
                                    )}
                                </Field>
                                <Field name="confirm_password">
                                    {({ field, form }) => (
                                        <TextInput
                                            id="confirm_password"
                                            name="confirm_password"
                                            label={'Confirm Password'}
                                            autoComplete="confirm password"
                                            password={true}
                                            error={errors.confirm_password && touched.confirm_password}
                                            message={errors.confirm_password}
                                            onChange={e => form.setFieldValue(field.name, e.target.value)}
                                        />
                                    )}
                                </Field>
                                <Button
                                    className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {isSubmitting ? 'updating...' : 'Update Password'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </div>
    )
}

export default DropdownUser
