export const yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]

export const sponsorOptions = [
    { label: 'Yes', value: 'sponsored' },
    { label: 'No', value: 'unsponsored' },
]

export const toggleButtonOptions = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
]

export const categoryOptions = [
    { label: 'BALLARAT REGION', value: 'BALLARAT REGION' },
    { label: 'BOOKING DONE BY WINALLIANZ', value: 'BOOKING DONE BY WINALLIANZ' },
    { label: 'FOLLOW UP', value: 'FOLLOW UP' },
    { label: 'GEELONG REGION', value: 'GEELONG REGION' },
    { label: 'GENERAL BUILDERS', value: 'GENERAL BUILDERS' },
    { label: 'INVOICE DONE', value: 'INVOICE DONE' },
    { label: 'NEEDS TO BE ALLOCATED', value: 'NEEDS TO BE ALLOCATED' },
    { label: 'NO INVOICE OR NOT PAID', value: 'NO INVOICE OR NOT PAID' },
    { label: 'PLEASE DELETE DOUBLE UP', value: 'PLEASE DELETE DOUBLE UP' },
    { label: 'REGIONAL', value: 'REGIONAL' },
    { label: 'SUBCONTRACTOR HAS BEEN PAID FOR THIS JOB', value: 'SUBCONTRACTOR HAS BEEN PAID FOR THIS JOB' },
    { label: 'WARRANTY COMPLETE BUT NNED TO INVOICE', value: 'WARRANTY COMPLETE BUT NNED TO INVOICE' },
    { label: 'WARRANTY COMPLETED AND SENT', value: 'WARRANTY COMPLETED AND SENT' },
    { label: 'WARRANTY COMPLETED BUT NOT SENT', value: 'WARRANTY COMPLETED BUT NOT SENT' },
    { label: 'WARRANTY TO BE COMPLETE', value: 'WARRANTY TO BE COMPLETE' },
]

export const taxTypeOptions = [
    { label: 'Including', value: 0 },
    { label: 'Exluding', value: 1 },
]

export const filterOptions = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
]

export const johnStatusOptions = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Approve', value: 'Approve' },
]

export const timeofDayOptions = [
    { label: 'AM Job', value: 'AM Job' },
    { label: 'PM Job', value: 'PM Job' },
]

export const jobStatusOptions = [
    { label: 'Incomplete', value: 'Incomplete' },
    { label: 'Issue', value: 'Issue' },
    { label: 'Done', value: 'Done' },
]

export const roleOptions = [
    { id: 'admin', value: 0, label: 'Admin' },
    { id: 'technician', value: 1, label: 'Technician' }
]

export const lengthOption = [
    {
        id: 10,
        label: 10,
    },
    {
        id: 25,
        label: 25,
    },
    {
        id: 50,
        label: 50,
    },
    {
        id: 100,
        label: 100,
    },
]
