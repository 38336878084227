import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import Tooltip from 'components/Ui/Tooltip'
import { Table } from 'components/Ui'
import dayjs from 'dayjs'

injectReducer('technicians', reducer)

export default function Technicians() {
    const dispatch = useDispatch()
    const { data, loading, tableData } = useSelector(state => state.technicians.data)
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab])

    const fetchData = () => dispatch(getData({ ...tableData }))

    const columns = useMemo(
        () => [
            {
                field: 'name',
                header: 'Technician Name',
                className: 'min-w-[150px]',
            },
            {
                field: 'user',
                header: 'Technician Email',
                className: 'min-w-[150px]',
            },
            {
                field: 'start_date',
                header: 'Holiday date(start)',
                className: 'min-w-[150px]',
                renderCell: row => row.start_date ? dayjs(row.start_date).format('DD-MMM-YYYY') : '-',
            },
            {
                field: 'end_date',
                header: 'Holiday date(end)',
                className: 'min-w-[150px]',
                renderCell: row => row.end_date ? dayjs(row.end_date).format('DD-MMM-YYYY') : '-',
            },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            <Tooltip title={'Edit'}>
                                <Link to={`/master-data/technicians/${row.id}/edit`} className="hover:text-primary">
                                    <HiOutlinePencilAlt size={20} />
                                </Link>
                            </Tooltip>
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Technicians</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    loading={loading}
                    filter={false}
                    datas={data}
                    columns={columns}
                    add_new={'/master-data/technicians/create'}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
        </>
    )
}
