import React, { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'
import { AutoSelect, Button, Checkbox, DatePicker, Select, TextInput, ToggleButton } from 'components/Form'
import { BiSearchAlt } from 'react-icons/bi'
import { AiFillCaretDown, AiFillCaretUp, AiFillPlusCircle, AiOutlinePlus } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { filterOptions, lengthOption, toggleButtonOptions } from 'utils/options'
import { useSelector } from 'react-redux'
import { HiOutlineMail, HiOutlineUserAdd } from 'react-icons/hi'
import Tooltip from './Tooltip'
import dayjs from 'dayjs'
import { getSuburbByDate } from 'services/EventService'

const defoltclassName = 'py-2 px-2 md:px-4 2xl:px-7'

const Table = ({
    columns,
    loading,
    datas,
    add_new,
    bulk_add = '',
    link,
    link_name,
    search_show = true,
    suburb_filter = false,
    filter = true,
    toggleButton = false,
    extraFilter = false,
    companyFilter = false,
    branchFilter = false,
    categoryFilter = false,
    select = false,
    mail = false,
    assign_user = false,
    dateFilter = false,
    buttons = [],
    name = 'datas',
    onChange,
    onMailClick,
    onAssignUserClick,
    pagingData,
    ids,
    setIds,
    grid = 'sm:flex-row sm:justify-between',
    subGrid = 'sm:flex-row sm:justify-between',
    children,
}) => {
    // const { t } = useTranslation()

    const { pageSize, pageIndex, total, sort, query, selectedTab, suburb, branch, company, category, startDate, endDate } = pagingData
    const { branchData, companyData } = useSelector(state => state.listData)
    const userInfo = useSelector(state => state.auth.user)
    console.log(suburb);
    
    var totalPage = Math.ceil(total / pageSize)
    var pages = []
    var loadingPages = ['Previous', '...', 'Next']
    const from = pageSize * (pageIndex - 1) + 1
    const to = total > pageSize * pageIndex ? pageSize * pageIndex : total

    for (let i = 1; i <= totalPage; i++) {
        if (i === 1) {
            pages.push('Previous')
            pages.push(i)
        } else if (i === totalPage) {
            pages.push(i)
            pages.push('Next')
        } else {
            pages.push(i)
        }
    }

    const ColumnTd = ({ row, column }) => {
        return (
            <td
                className={`border-b border-stroke dark:border-strokedark items-center text-sm text-black dark:text-white ${defoltclassName} ${column.className}`}
            >
                {column.renderCell ? column.renderCell(row) : row[column.field]}
            </td>
        )
    }
    const gridClass = `flex flex-col items-center gap-3 ${grid}`
    const subGridClass = `flex flex-col items-center gap-3 ${subGrid}`

    const [suburbOptions, setSuburbOptions] = useState([]);

    useEffect(() => {
        getSuburbs({start_date: startDate, end_date:endDate})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate])

    const getSuburbs = (data) => {
        getSuburbByDate(data)
            .then((response) => {
                const suburbs = response.data?.suburbs?.map((suburb) => ({
                    label: suburb,
                    value: suburb
                }));
    
                setSuburbOptions(suburbs || []);
            })
            .catch((error) => console.error("Error fetching suburbs:", error));
    };

    const onPaginationChange = page => {
        const newTableData = { ...pagingData }
        newTableData.pageIndex = page
        onChange(newTableData)
    }

    const onSelectChange = val => {
        const newTableData = { ...pagingData }
        newTableData.pageSize = val
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onSort = sort => {
        const newTableData = { ...pagingData }
        newTableData.sort = sort
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onSearch = val => {
        const newTableData = { ...pagingData }
        newTableData.query = val
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onTabChange = value => {
        const newTableData = { ...pagingData }
        newTableData.selectedTab = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onSuburbChange = value => {
        const newTableData = { ...pagingData }
        newTableData.suburb = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onBranchChange = value => {
        const newTableData = { ...pagingData }
        newTableData.branch = [value]
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onCompanyChange = value => {
        const newTableData = { ...pagingData }
        newTableData.company = value
        newTableData.branch = ''
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onCategoryChange = value => {
        const newTableData = { ...pagingData }
        newTableData.category = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onStartDateChange = value => {
        const newTableData = { ...pagingData }
        newTableData.startDate = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onEndDateChange = value => {
        const newTableData = { ...pagingData }
        newTableData.endDate = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    return (
        <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
            {dateFilter && (
                <div className={`px-3 md:px-4 xl:px-6 pt-3 md:pt-4 xl:pt-6 ${gridClass}`}>
                    <div className={subGridClass}>
                    </div>
                    <div className={subGridClass}>
                        <DatePicker
                            label="Start Date"
                            name="date_of_job"
                            value={startDate ? dayjs(startDate).format('YYYY-MM-DD') : null}
                            onChange={e => onStartDateChange(e.target.value ? dayjs(e.target.value).format('DD MMM YYYY') : '')}
                        />
                        <DatePicker
                            label="End Date"
                            name="date_of_job"
                            disabled={!startDate}
                            value={endDate ? dayjs(endDate).format('YYYY-MM-DD') : null}
                            onChange={e => onEndDateChange(e.target.value ? dayjs(e.target.value).format('DD MMM YYYY') : '')}
                        />
                    </div>
                </div>
            )}
            <div className={`p-3 md:p-4 xl:p-6 ${gridClass}`}>
                <div className={subGridClass}>
                    {toggleButton ? <ToggleButton
                        group={true}
                        label="Toggle Settings"
                        options={toggleButtonOptions}
                        value={selectedTab ?? ''}
                        onChange={onTabChange}
                    /> : null}
                    {children}
                </div>
                <div className={subGridClass}>
                    {buttons.length > 0 && (
                        <div className="flex flex-row items-center gap-3">
                            {buttons.map((text, index) => (
                                <Button
                                    key={index}
                                    as="a"
                                    // href={url}
                                    data={{ download: text }}
                                    // onClick={() => download(text)}
                                    className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                >
                                    {text}
                                </Button>
                            ))}
                        </div>
                    )}
                    {companyFilter && (
                        <Select
                            id="length"
                            name="length"
                            placeholder="Company"
                            value={company}
                            className="min-w-28"
                            options={companyData}
                            onChange={e => onCompanyChange?.(e.target.value)}
                        />
                    )}
                    {branchFilter && (
                        <Select
                            id="length"
                            name="length"
                            placeholder="Branch"
                            value={branch ? branch[0] : ''}
                            className="min-w-28"
                            options={
                                userInfo?.branches?.length > 1
                                    ? userInfo?.branches
                                    : branchData?.filter(item => (company ? item?.company?.id?.toString() === company : item))
                            }
                            onChange={e => onBranchChange?.(e.target.value)}
                        />
                    )}
                    {categoryFilter && (
                        <Select
                            id="length"
                            name="length"
                            placeholder="Category"
                            value={category}
                            className="min-w-28"
                            options={branchData}
                            onChange={e => onCategoryChange?.(e.target.value)}
                        />
                    )}
                    {filter && (
                        <Select
                            id="length"
                            name="length"
                            value={selectedTab}
                            className="min-w-28"
                            options={filterOptions}
                            onChange={e => onTabChange?.(e.target.value)}
                        />
                    )}
                    {suburb_filter && (
                        <AutoSelect
                        options={suburbOptions}
                        size="sm"
                        placeholder="Suburbs"
                        className="md:col-span-2"
                        value={suburb}
                        onChange={e => onSuburbChange?.(e)}
                    />
                    )}
                    {search_show && (
                        <TextInput
                            id="search"
                            name="search"
                            value={query ?? ''}
                            onChange={e => onSearch?.(e.target.value)}
                            placeholder={'Search'}
                            icon={<BiSearchAlt />}
                            className="w-full sm:w-auto"
                        />
                    )}
                    {assign_user && (
                        <Tooltip title={'Assign Technicians'}>
                            <Button
                                className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                as="button"
                                onClick={onAssignUserClick}
                                disabled={ids ? ids.length < 1 : true}
                            >
                                <HiOutlineUserAdd size={24} />
                            </Button>
                        </Tooltip>
                    )}
                    {mail && (
                        <Tooltip title={'Send Mail'}>
                            <Button
                                className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                as="button"
                                onClick={onMailClick}
                            >
                                <HiOutlineMail size={24} />
                            </Button>
                        </Tooltip>
                    )}
                    {(link || add_new) && (
                        <div className="flex flex-row items-center w-full gap-3 sm:w-auto">
                            {link && (
                                <Button
                                    href={link}
                                    as="button"
                                    className="text-primary border-primary dark:text-secondary dark:border-secondary"
                                >
                                    {link_name}
                                </Button>
                            )}
                            {add_new && (
                                <Link to={add_new}>
                                    <Button
                                        as="button"
                                        className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    >
                                        <AiOutlinePlus size={20} /> {'Add New'}
                                    </Button>
                                </Link>
                            )}

                            {bulk_add && (
                                <Link to={bulk_add}>
                                    <Button
                                        as="button"
                                        className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    >
                                        <AiFillPlusCircle size={20} /> {' Bulk Add'}
                                    </Button>
                                </Link>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                    <thead>
                        <tr className={`bg-primary dark:bg-secondary bg-opacity-90 text-left`}>
                            {select && (
                                <th className="px-3 py-2 font-medium text-white md:px-3 2xl:px-3">
                                    <Checkbox
                                        id={'select_all'}
                                        name={'select_all'}
                                        checked={datas?.length !== 0 && datas?.filter(item => item.is_active)?.length === ids.length}
                                        onChange={() => setIds(datas?.filter(item => item.is_active)?.length !== ids.length ? datas?.filter(item => item.is_active)?.map(row => row.id) : [])}
                                    />
                                </th>
                            )}
                            {columns.map((row, index) => (
                                <th key={index} className={`font-medium text-white ${defoltclassName} ${row.className}`}>
                                    {row.orderable ? (
                                        <div
                                            className="flex items-center justify-between gap-1 cursor-pointer"
                                            onClick={() => {
                                                if (sort[0].dir !== 'desc') {
                                                    const data = [
                                                        {
                                                            column: row.field,
                                                            dir:
                                                                sort[0].column === row.field && sort[0].dir === 'asc'
                                                                    ? 'desc'
                                                                    : 'asc',
                                                        },
                                                    ]
                                                    onSort?.(data)
                                                } else {
                                                    onSort?.([{ column: '', dir: '' }])
                                                }
                                            }}
                                        >
                                            <p>{row.header}</p>
                                            <div className="inline-flex flex-col space-y-[2px]">
                                                {(sort[0].column !== row.field || sort[0].dir !== 'asc') && (
                                                    <AiFillCaretUp size={14} style={{ margin: '-1px' }} />
                                                )}
                                                {(sort[0].column !== row.field || sort[0].dir !== 'desc') && (
                                                    <AiFillCaretDown size={14} style={{ margin: '-1px' }} />
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        row.header
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && datas?.map((row, index) => (
                            <tr key={index} className="hover:bg-whiten dark:hover:bg-boxdark2">
                                {select && (
                                    <td className="items-center px-3 py-2 text-sm text-black border-b border-stroke dark:border-strokedark dark:text-white md:px-3 2xl:px-3">
                                        {row?.is_active && <Checkbox
                                            id={'select_' + row.id}
                                            name={'select_' + row.id}
                                            checked={ids.includes(row.id)}
                                            onChange={() => {
                                                var selectIds = [...ids]
                                                if (!selectIds.includes(row.id)) {
                                                    selectIds.push(row.id)
                                                } else {
                                                    selectIds = selectIds.filter(g => g !== row.id)
                                                }
                                                setIds(selectIds)
                                            }}
                                        />}
                                    </td>
                                )}
                                {columns.map((column, key) => (
                                    <ColumnTd key={key} row={row} column={column} />
                                ))}
                            </tr>
                        ))}
                        {(datas?.length === 0 || loading) && (
                            <tr>
                                <td
                                    className={`border-b border-stroke dark:border-strokedark items-center text-center text-primary dark:text-white ${defoltclassName}`}
                                    colSpan="100%"
                                >
                                    {loading ? 'Loading...' : 'Data not found'}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={`flex justify-end p-3 sm:p-4 xl:p-6`}>
                <div className="flex flex-row items-center gap-2">
                    <p className="text-black dark:text-white">{'Show'}</p>
                    <Select
                        id="length"
                        name="length"
                        value={pageSize ?? 10}
                        className="w-17"
                        options={lengthOption}
                        onChange={e => onSelectChange?.(e.target.value)}
                    />
                    <p className="text-black dark:text-white">{'Entries'}</p>
                </div>
                {children}
            </div>
            {(totalPage > 1 || loading) && (
                <div className="flex flex-col gap-3 px-3 pb-3 sm:px-4 xl:px-6 sm:pb-4 xl:pb-6 sm:flex-row sm:items-center sm:justify-between">
                    <p className="text-sm text-black dark:text-white">
                        {`Showing ${from} to 
                        ${to} of ${total} entries`}
                    </p>
                    <nav>
                        <ul className="flex flex-wrap items-center w-full">
                            {(loading ? loadingPages : pages)?.map((link, key) => (
                                <li key={key}>
                                    {link !== pageIndex ? (
                                        <div
                                            onClick={() => {
                                                if (loading) return 0
                                                if (typeof link === 'string') {
                                                    if (link === 'Previous') {
                                                        if (pageIndex !== 1) {
                                                            onPaginationChange(pageIndex - 1)
                                                        }
                                                    } else if (link === 'Next') {
                                                        if (pageIndex !== totalPage) {
                                                            onPaginationChange(pageIndex + 1)
                                                        }
                                                    }
                                                } else {
                                                    onPaginationChange(link)
                                                }
                                            }}
                                            className={`flex items-center justify-center border py-[5px] px-4 font-medium text-sm  
                                                ${key !== 0 && link !== pageIndex && 'border-l-transparent'}
                                                ${link === pageIndex
                                                    ? 'border-primary bg-gray text-primary dark:border-secondary dark:bg-graydark'
                                                    : `text-black dark:text-white border-stroke ${link === 'Previous' ? (pageIndex !== 1 && 'hover:border-primary hover:bg-gray hover:text-primary dark:hover:text-secondary dark:hover:border-secondary dark:hover:bg-graydark') : (link === 'Next' && pageIndex !== totalPage && 'hover:border-primary hover:bg-gray hover:text-primary dark:hover:text-secondary dark:hover:border-secondary dark:hover:bg-graydark')} dark:border-strokedark `
                                                }
                                                ${key === 0 && 'rounded-l-md'}
                                                ${pages.length === key + 1 && 'rounded-r-md'}
                                            `}
                                        >
                                            {link}
                                        </div>
                                    ) : (
                                        <div
                                            className={`flex items-center justify-center border py-[5px] px-4 font-medium text-sm 
                                                ${key !== 0 && link !== pageIndex && 'border-l-transparent'}
                                                ${key === 0 && 'border-r-transparent'}
                                                ${link === pageIndex
                                                    ? 'border-primary bg-gray text-primary dark:border-secondary dark:bg-graydark'
                                                    : 'text-black dark:text-white border-stroke dark:border-strokedark'
                                                }
                                                ${key === 0 && 'rounded-l-md'}
                                                ${pages.length === key + 1 && 'rounded-r-md'}
                                            `}
                                        >
                                            {link}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    )
}
export default Table
