import React, { useEffect, useState } from 'react'
import Header from 'components/layout/Header'
import Sidebar from 'components/layout/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import useAuth from 'utils/hooks/useAuth'
import { onSignInSuccess } from 'store/auth/sessionSlice'
import { getApiAdminProfile } from 'services/AuthService'
import { setUser } from 'store/auth/userSlice'

export default function AppLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const { authenticated } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { token } = useParams();
    const dispatch = useDispatch();
    const { role } = useSelector(state => state.auth.session)

    useEffect(() => {
        if (!authenticated && !token) {
            navigate('/login')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useEffect(() => {
        if (!authenticated && token) {
            // Dispatch the sign-in success action
            dispatch(onSignInSuccess({ access: token, refresh: '' }));
            getApiAdminProfile()
                .then((resp) => {
                    console.log(resp.data);
                    
                    if (resp.data) {
                        dispatch(
                            setUser(resp.data)
                        )
                    }
                })
                .catch((error) => console.log(error))
        }   
    }, [authenticated, token, dispatch]);

    useEffect(() => {
        if (authenticated && role) {
            navigate('/schedules')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role, location.pathname])

    // const fetchData = () => {
    //     getApiProfile()
    //         .then(response => {
    //             const user = {
    //                 ...userInfo,
    //                 email: response.data.email,
    //             }
    //             dispatch(setUser(user))
    //         })
    //         .catch(error => console.log('Error: ', error))
    // }

    return (
        <div className="dark:bg-boxdark2 dark:text-bodydark">
            <div className="flex h-screen overflow-hidden">
                {role ? null : <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />}
                <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    <main>
                        <div className="p-4 mx-auto max-w-screen-2xl md:p-5 2xl:p-8">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
