import { forwardRef, useRef } from 'react'
import { Link } from 'react-router-dom'

export default forwardRef(function Button({ className = '', disabled, children, ...props }, ref) {
    const input = useRef()
    const Div = props.href ? Link : 'button'
    return (
        <Div
            {...props}
            className={`flex items-center justify-center cursor-pointer rounded-lg border py-2 px-6 transition hover:bg-opacity-90 ${
                disabled && 'opacity-25 cursor-not-allowed'
            } ${className}`}
            disabled={disabled}
            ref={ref || input}
        >
            {children}
        </Div>
    )
})
