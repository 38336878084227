export default function RadioButton({ className = '', group = false, error = '', ...props }) {
    if (group) {
        return (
            <div>
                <label className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {props.label}
                </label>
                <div className={className}>
                    {props.datas.map((data, key) => (
                        <RadioButton
                            key={key}
                            id={data.id}
                            name={props.name}
                            checked={data.value === props.value}
                            label={data.label}
                            message={data.message ?? ''}
                            onChange={() => props.onChange(data.value)}
                            disabled={props.disabled ?? false}
                        />
                    ))}
                </div>
                {error && <p className="text-sm text-danger">{error}</p>}
            </div>
        )
    }
    return (
        <div className={className}>
            <label
                htmlFor={props.id}
                className={`flex items-center select-none ${!props.disabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            >
                <div className="relative">
                    <input {...props} type="radio" className="sr-only " />
                    <div
                        className={`mr-2 flex h-5 w-5 items-center justify-center rounded-full border ${
                            props.checked && '!border-4'
                        } ${props.disabled ? 'bg-whiter dark:bg-black' : 'border-primary dark:border-secondary'}`}
                    >
                        <span className={`h-2 w-2 rounded-full ${props.checked && 'bg-white dark:bg-transparent'}`}></span>
                    </div>
                </div>
                {props.label}
                <br />
                {props.message && props.message}
            </label>
        </div>
    )
}
