import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, DatePicker, TextInput } from 'components/Form'
import { createApiRecord, updateApiRecord } from 'services/TechnicianService'
import { useDispatch, useSelector } from 'react-redux'
import { getRecord } from './store/dataSlice'
import reducer from './store'
import { injectReducer } from 'store'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import dayjs from 'dayjs'
injectReducer('technicians', reducer)

const validationSchema = Yup.object().shape({
    name: Yup.string().required('technician name is required'),
    user: Yup.string()
        .required('email is required')
        .email('email is invalid')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'email is invalid'),
})

const Create = props => {
    const { initialData } = props
    const { id } = useParams()
    const { record, loading } = useSelector(state => state.technicians.data)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            fetchData(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const fetchData = id => dispatch(getRecord(id))

    const handleDiscard = () => navigate('/master-data/technicians')

    const handleSubmit = (value, setSubmitting) => {
        value.start_date = value.start_date ? value.start_date : null
        value.end_date = value.end_date ? value.end_date : null
        setSubmitting(true)
        if (id) {
            updateApiRecord(id, value)
                .then(response => {
                    setSubmitting(false)
                    Success('Updated Successfully!')
                    handleDiscard()
                })
                .catch(error => {console.log('Error: ', error); setSubmitting(false)})
        } else {
            createApiRecord(value)
                .then(response => {
                    setSubmitting(false)
                    Success('Created Successfully!')
                    handleDiscard()
                })
                .catch(error => {console.log('Error: ', error); setSubmitting(false)})
        }
    }

    if (loading && id) {
        return null
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Technicians</h2>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="px-5 py-3 border-b border-stroke dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">{id ? 'Update Technician' : 'Add New Technician'}</h3>
                    </div>
                    <div className="flex flex-col gap-3 p-6">
                        <Formik
                            initialValues={id ? { ...record, secondary_images: [] } : initialData}
                            validationSchema={validationSchema}
                            onSubmit={(values, {setSubmitting}) => {
                                handleSubmit(values, setSubmitting)
                            }}
                        >
                            {({ values, touched, errors, isSubmitting }) => (
                                <Form>
                                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                                        <Field name="name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Technician Name"
                                                    name="name"
                                                    error={errors.name && touched.name}
                                                    message={errors.name}
                                                    value={values?.name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="user">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Email"
                                                    name="user"
                                                    error={errors.user && touched.user}
                                                    message={errors.user}
                                                    value={values?.user}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="start_date">
                                            {({ field, form }) => (
                                                <DatePicker
                                                    className="md:col-span-2"
                                                    label="Holiday Date(Start)"
                                                    name="start_date"
                                                    error={errors.start_date && touched.start_date}
                                                    message={errors.start_date}
                                                    value={values?.start_date ? dayjs(values.start_date).format('YYYY-MM-DD') : ''}
                                                    onChange={e => {form.setFieldValue(field.name, dayjs(e.target.value).format('YYYY-MM-DD'))
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name="end_date">
                                            {({ field, form }) => (
                                                <DatePicker
                                                    className="md:col-span-2"
                                                    label="Holiday Date(End)"
                                                    name="end_date"
                                                    error={errors.end_date && touched.end_date}
                                                    message={errors.end_date}
                                                    value={values?.end_date ? dayjs(values.end_date).format('YYYY-MM-DD') : ''}
                                                    onChange={e => {form.setFieldValue(field.name, dayjs(e.target.value).format('YYYY-MM-DD'))
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="flex items-center justify-end gap-4 mt-3">
                                        <Button
                                            className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                            onClick={handleDiscard}
                                        >
                                            Discard
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

Create.defaultProps = {
    initialData: {
        name: '',
        user: '',
        start_date: '',
        end_date: '',
    },
}

export default Create
