import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addMinutes } from 'components/helper'
import { getApiEvents } from 'services/EventService'

export const getData = createAsyncThunk('event/data/getData', async data => {
    const response = await getApiEvents(data)
    return response.data?.results?.filter((item) => item.is_active).map((item) => {

        const eventObj = {
            title: item?.latest_technician ? item?.latest_technician?.technician?.name : item?.subject_line,
            start: `${item?.date_of_job} ${item?.time_of_job}`,
            end: item.date_of_job,
            builder: item?.builder?.name,
            type_of_job: item?.type_of_job?.name,
            address: item?.address,
            date: item?.date_of_job,
            pour_date: item?.pour_date,
            po: item?.po,
            site_supervisor: item?.site_supervisor,
            booked_by: item?.booked_by?.name,
            technician: item?.latest_technician?.technician?.name,
            prices: item?.price_table,
            note: item?.note,
            scheduled_task_id: item.id,
            details: { ...item }
        }
        
        return eventObj;
    })
})

const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 50,
    query: '',
    selectedTab: '',
    sort: [{ column: '', dir: '' }],
}

const dataSlice = createSlice({
    name: 'event/data',
    initialState: {
        loading: true,
        data: [],
        tableData: initialTableData,
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.tableData = payload
        },
    },
    extraReducers: event => {
        event
            .addCase(getData.pending, state => {
                state.loading = true
                state.tableData.total = 0
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload
                state.tableData.total = payload.count
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
            })
    },
})

export const { setData, setTableData } = dataSlice.actions

export default dataSlice.reducer
