import ApiService from './ApiService'

export const getApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.status = data.selectedTab
        }
        if (data.startDate) {
            datas.start_date = data.startDate
        }
        if (data.endDate) {
            datas.end_date = data.endDate
        }
        if (data.suburb) {
            datas.suburb = data.suburb  
        }
    }
    return ApiService.get('/event/', datas)
}
export const createApiRecord = data => ApiService.post('/event/', data)
export const getApiRecord = id => ApiService.get(`/event/${id}/`)
export const updateApiRecord = (id, data) => ApiService.put(`/event/${id}/`, data)
export const updateApiStatus = (id, data) => ApiService.patch(`/event/${id}/`, data)
export const getApiEvents = () => ApiService.get('/event/')
export const getPostcode = (query) => ApiService.get(`/event/postcodes/`)
export const getSuburb = (data) => ApiService.get(`/event/suburbs/`, data)
export const getSuburbByDate = (data) => ApiService.get(`/event/events-by-date/`, data)
export const assignTechnicianApi = data => ApiService.post('/technician-assign/', data)
export const sendMailApi = data => ApiService.post('/bulkjobsmail/', data)
export const updateJohnStatusApi = data => ApiService.post('/update-john-status/', data)
