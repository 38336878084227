import BaseService from './BaseService'

const ApiService = {
    get(url, params) {
        return BaseService({
            method: 'GET',
            url: url,
            params,
        })
    },
    post(url, data) {
        return BaseService({
            method: 'POST',
            url: url,
            data: data ? JSON.stringify(data) : null,
        })
    },
    patch(url, data) {
        return BaseService({
            method: 'PATCH',
            url: url,
            data: data ? JSON.stringify(data) : null,
        })
    },
    update(url, slug, data) {
        return BaseService({
            method: 'PUT',
            url: url + '/' + slug,
            data: data ? JSON.stringify(data) : null,
        })
    },
    put(url, data) {
        return BaseService({
            method: 'PUT',
            url: url,
            data: data ? JSON.stringify(data) : null,
        })
    },
    delete(url, data) {
        return BaseService({
            method: 'DELETE',
            url: url,
            data: data,
        })
    },
    filePost(url, data) {
        return BaseService({
            method: 'POST',
            url: url,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    filePut(url, data) {
        return BaseService({
            method: 'PUT',
            url: url,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    filePatch(url, data) {
        return BaseService({
            method: 'PATCH',
            url: url,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
}

export default ApiService
