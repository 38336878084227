import { Fragment } from 'react'
// import { Link } from '@inertiajs/react'
import { Menu, Transition } from '@headlessui/react'
import { BiChevronDown } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const Dropdown = ({ children }) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {children}
        </Menu>
    )
}

const Trigger = ({ children, downShow = true }) => {
    return (
        <Menu.Button className="flex items-center gap-1">
            {children}
            {downShow && <BiChevronDown size={25} className="hidden fill-current sm:block" />}
        </Menu.Button>
    )
}

const Content = ({ align = 'right', width = '40', className = 'right-0', contentClasses = '', children }) => {
    let alignmentClasses = 'origin-top'

    if (align === 'left') {
        alignmentClasses = 'origin-top-left left-0'
    } else if (align === 'right') {
        alignmentClasses = 'origin-top-right -right-10 sm:right-0'
    }

    let widthClasses = ''

    if (width === '80') {
        widthClasses = 'w-80'
    } else if (width === '60') {
        widthClasses = 'w-60'
    } else if (width === '40') {
        widthClasses = 'w-40'
    }

    return (
        <>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute ${className} mt-2 flex flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark ${alignmentClasses} ${widthClasses}`}
                >
                    <div className={`flex flex-col ` + contentClasses}>{children}</div>
                </Menu.Items>
            </Transition>
        </>
    )
}

const DropdownLink = ({ className = '', children, ...props }) => {
    return (
        <Menu.Item>
            <Link
                {...props}
                className={
                    'flex items-center gap-3 px-4 py-3 text-sm font-medium duration-300 ease-in-out lg:text-base hover:bg-gray2 dark:hover:bg-strokedark ' +
                    // + 'group relative duration-300 ease-linear before:absolute before:left-0 before:h-0 before:w-1 before:bg-primary before:duration-300 before:ease-linear hover:before:h-full'
                    className
                }
            >
                {children}
            </Link>
        </Menu.Item>
    )
}

Dropdown.Trigger = Trigger
Dropdown.Content = Content
Dropdown.Link = DropdownLink

export default Dropdown
