import { BrowserRouter } from 'react-router-dom'
import 'assets/css/app.css'
import store, { persistor } from 'store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Router from 'route'
import { MsalProvider } from '@azure/msal-react'
import msalInstance from 'utils/authConfig'

export default function App() {
    return (
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <ToastContainer />
                        <Router />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </MsalProvider>
    )
}
