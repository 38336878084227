import { combineReducers } from 'redux'
import auth from './auth'
import locale from './locale/localeSlice'
import listData from './data/dataSlice'

const rootReducer = asyncReducers => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        locale,
        listData,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
