import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, TextInput } from 'components/Form'
import { createApiRecord, updateApiRecord } from 'services/JobService'
import { useDispatch, useSelector } from 'react-redux'
import { getRecord } from './store/dataSlice'
import reducer from './store'
import { injectReducer } from 'store'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
injectReducer('job', reducer)

const validationSchema = Yup.object().shape({
    name: Yup.string().required('job name is required'),
})

const Create = props => {
    const { initialData } = props
    const { id } = useParams()
    const { record, loading } = useSelector(state => state.job.data)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            fetchData(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const fetchData = id => dispatch(getRecord(id))

    const handleDiscard = () => navigate('/master-data/job')

    const handleSubmit = (value, setSubmitting) => {
        setSubmitting(true)
        if (id) {
            updateApiRecord(id, value)
                .then(response => {
                    setSubmitting(false)
                    Success('Updated Successfully!')
                    handleDiscard()
                })
                .catch(error => {console.log('Error: ', error); setSubmitting(false)})
        } else {
            createApiRecord(value)
                .then(response => {
                    setSubmitting(false)
                    Success('Created Successfully!')
                    handleDiscard()
                })
                .catch(error => {console.log('Error: ', error); setSubmitting(false)})
        }
    }

    if (loading && id) {
        return null
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Jobs</h2>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="px-5 py-3 border-b border-stroke dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">{id ? 'Update Job' : 'Add New Job'}</h3>
                    </div>
                    <div className="flex flex-col gap-3 p-6">
                        <Formik
                            initialValues={id ? { ...record, secondary_images: [] } : initialData}
                            validationSchema={validationSchema}
                            onSubmit={(values, {setSubmitting}) => {
                                handleSubmit(values, setSubmitting)
                            }}
                        >
                            {({ values, touched, errors, isSubmitting }) => (
                                <Form>
                                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                                        <Field name="name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Job Name"
                                                    name="name"
                                                    error={errors.name && touched.name}
                                                    message={errors.name}
                                                    value={values?.name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="type_of_job">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Type of job"
                                                    name="type_of_job"
                                                    error={errors.type_of_job && touched.type_of_job}
                                                    message={errors.type_of_job}
                                                    value={values?.type_of_job}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="flex items-center justify-end gap-4 mt-3">
                                        <Button
                                            className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                            onClick={handleDiscard}
                                        >
                                            Discard
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

Create.defaultProps = {
    initialData: {
        name: '',
        type_of_job: '',
    },
}

export default Create
