import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { updateApiStatus } from 'services/JobService'
import Tooltip from 'components/Ui/Tooltip'
import { ConfirmationModal, Table } from 'components/Ui'
import { Success } from 'utils/alerts'
import { status } from 'components/helper'

injectReducer('job', reducer)

export default function Job() {
    const dispatch = useDispatch()
    const { data, loading, tableData } = useSelector(state => state.job.data)
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab])

    const fetchData = () => dispatch(getData({ ...tableData }))

    const handleStatus = (id, row) => {
        updateApiStatus(id, row)
            .then(() => {
                Success('Status Updated Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const columns = useMemo(
        () => [
            {
                field: 'name',
                header: 'Job Name',
                className: 'min-w-[150px]',
            },
            {
                header: 'Status',
                renderCell: row => {
                    return (
                        <ConfirmationModal
                            onConfirm={() => {
                                handleStatus(row.id, { is_active: !row.is_active })
                            }}
                        >
                            {status(row.is_active)}
                        </ConfirmationModal>
                    )
                },
            },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            <Tooltip title={'Edit'}>
                                <Link to={`/master-data/job/${row.id}/edit`} className="hover:text-primary">
                                    <HiOutlinePencilAlt size={20} />
                                </Link>
                            </Tooltip>
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Jobs</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    loading={loading}
                    datas={data}
                    columns={columns}
                    add_new={'/master-data/job/create'}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
        </>
    )
}
