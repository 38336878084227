import { forwardRef, useRef } from 'react';

const ToggleButton = forwardRef(function ToggleButton(
    { className = '', options, disabled, value='', onChange, ...props },
    ref
) {
    const input = useRef();
    const Div = 'button';

    const handleToggle = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className='flex'>
            {options.map((item, index) => <Div
                {...props}
                key={item?.value}
                onClick={handleToggle}
                className={`${item.value === value && 'bg-primary text-white'} ${index === 0 && 'rounded-s-lg'} ${index === (options.length - 1) && 'rounded-e-lg'} flex items-center justify-center cursor-pointer border border-primary py-2 px-6 transition hover:bg-opacity-90 ${disabled ? 'opacity-25 cursor-not-allowed' : 'opacity-100'
                    } ${className}`}
                disabled={disabled}
                ref={ref || input}
                value={item?.value}
            >
                {item?.label}
            </Div>)}
        </div>
    );
});

export default ToggleButton;
