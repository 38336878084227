import { Button, RadioButton, TextInput } from 'components/Form'
import { HiOutlineUser } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import useAuth from 'utils/hooks/useAuth'
import { Error, Success } from 'utils/alerts'
import { TfiMicrosoft } from "react-icons/tfi";
import { roleOptions } from 'utils/options'
import { setRole } from 'store/auth/sessionSlice'
import { useDispatch, useSelector } from 'react-redux'
const { REACT_APP_URL } = process.env

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
})

export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { signIn } = useAuth()
    const { role } = useSelector(state => state.auth.session)

    const onSignIn = async (values, setSubmitting) => {
        const { email, password } = values
        setSubmitting(true)
        const result = await signIn({ email, password })

        if (result.status !== 'failed') {
            if (result.new_user) {
                navigate('/change-password')
            } else {
                Success('Login successfully!')
                navigate('/')
            }

        } else {
            result.message && Error(result.message)
        }
        setSubmitting(false)
    }

    return (
        <>
            <h2 className="mb-5 text-lg font-bold text-black dark:text-white sm:text-title-lg">Login to PMS</h2>
            <div className='mb-3'>
                <RadioButton
                    group={true}
                    name="userRole"
                    value={role}
                    onChange={(e) => dispatch(setRole(e))}
                    datas={roleOptions}
                    className="flex gap-4"  // Tailwind utility for spacing between radio buttons
                />
            </div>
            {role ? (
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        onSignIn(values, setSubmitting)
                    }}
                >
                    {({ touched, errors, isSubmitting }) => (
                        <Form className="flex flex-col gap-3">
                            <Field name="email">
                                {({ field, form }) => (
                                    <TextInput
                                        type="text"
                                        id="email"
                                        name="email"
                                        label={'Email'}
                                        autoComplete="email"
                                        isFocused={true}
                                        icon={<HiOutlineUser />}
                                        error={errors.email && touched.email}
                                        message={errors.email}
                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                    />
                                )}
                            </Field>
                            <Field name="password">
                                {({ field, form }) => (
                                    <TextInput
                                        id="password"
                                        name="password"
                                        label={'Password'}
                                        autoComplete="password"
                                        password={true}
                                        error={errors.password && touched.password}
                                        message={errors.password}
                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                    />
                                )}
                            </Field>
                            <Button
                                className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                disabled={isSubmitting}
                                type="submit"
                            >
                                Sign In
                            </Button>
                            <div className="text-center">
                                <p className="font-medium">
                                    <Link to="/forgot-password">Forgot your password?</Link>
                                </p>
                            </div>
                            {/* <div className="flex items-center justify-center gap-1">
                                <span>or sign in with </span>
                                <TfiMicrosoft />
                                <a href={`${REACT_APP_URL}/login/`}>
                                    <span className='font-medium cursor-pointer'>
                                        Microsoft
                                    </span>
                                </a>
                            </div> */}
                        </Form>
                    )}
                </Formik>
            ) : (
                <div className='h-[226px]'>
                    <a href={`${REACT_APP_URL}/login/`}>
                        <Button
                            className="w-full mt-10 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                            type="button"
                        >
                            <span className='pr-2'>sign in with </span>
                            <TfiMicrosoft />
                            <span className='pl-1'>
                                Microsoft
                            </span>
                        </Button>
                    </a>
                </div>
            )}
        </>
    )
}
