const InfoField = ({ grid = true, title, value }) => {
    return (
        <div className={`${grid ? 'grid grid-cols-2 gap-4' : ''}`}>
            <span className="font-semibold text-black dark:text-white">
                {title}
            </span>
            <p className="text-gray-700 dark:text-white">{value}</p>
        </div>
    )
}

export default InfoField
